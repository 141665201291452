import React, {useEffect, useState} from 'react';
import styles from "./CreateNewCourseAdmin.module.css"
import {RiArrowDownSLine} from "react-icons/ri";
import SelctorForCourses from "./SelctorForCourses/SelctorForCourses";
import Button from "../../../../Components/./Button/button";
import AdminModal from "./AdminModal/AdminModal";
import axios from "../../../../axios/axios";
import AssignIDType from "../../AdminTest/TestsListInfo/AdminCreateATest/AssignIDType/AssignIDType";
import CourseChackbox from "./CourseChackbox/CourseChackbox";
import CheckBoxMulty from "../../AdminMyGroup/AdminCreateNewGroup/CheckBoxMulty/CheckBoxMulty";
function CreateNewCourseAdmin({onUpdate}) {
    const [open, setOpen] = useState(false)
    const [modalOp, setModalOp] = useState(false)
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedQuizz, setSelectedQuizz] = useState([]);
    const [price, setPrice] = useState('');
    const [erros, setErros] = useState(true);
    const [lessonsTitle, setLessonsTitle] = useState('');
    const [sales, setSales] = useState('');
    const [coursName, setCoursName] = useState('');
    const [coursNameRu, setCoursNameRu] = useState('');
    const [coursNameAm, setCoursNameAm] = useState('');
    const [desc, setDesc] = useState('');
    const [descru, setDescru] = useState('');
    const [descarm, setDescarm] = useState('');
    const type = ['Group','Individual']
    const format = ['Online','Offline','Hybrid']
    const level = ['Beginner','Intermediate','Advanced']
    const [openCountry, setOpenCountry] = useState(false);
    const [selectCountry, setSelectCountry] = useState('');
    const [individualTitle, setIndividualTitle] = useState('');
    const [individualDescription, setIndividualDescription] = useState('');
    const [individualDuration, setIndividualDuration] = useState('');
    const [whyThisCourse, setWhyThisCourse] = useState('• ');
    const [whyThisCourserus, setWhyThisCourserus] = useState('• ');
    const [whyThisCoursearm, setWhyThisCoursearm] = useState('• ');
    const [quizz, setQuizz] = useState('');
    const [motivationDescription, setMotivationDescription] = useState('')
    const [motivationDescriptionRu, setMotivationDescriptionRu] = useState('')
    const [motivationDescriptionAm, setMotivationDescriptionAm] = useState('')
    const [teacherList, setTeacherList] = useState([]);
    const [openFormat, setOpenFormat] = useState(false);
    const [openFormat1, setOpenFormat1] = useState(false);
    const [selectFormat, setSelectFormat] = useState('');
    const [open5, setOpen5] = useState(false);
    const [teacher, setTeacher] = useState([{ id: '', title: '' }]);
    const [openLevel, setOpenLevel] = useState(false);
    const [selectLevel, setSelectLevel] = useState('');
    const [maxHomeworkPoints, setMaxHomeworkPoints] = useState('');
    const [maxQuizPoints, setMaxQuizPoints] = useState('');
    const [maxInterviewPoints, setMaxInterviewPoints] = useState('');
     const [moreDetails,setMoreDetails] = useState([
        {
            whom:'',
            whomrus:'',
            whomarm:'',
            text:'',
            textrus:'',
            textarm:'',
        }
    ])
    const [trainers,setTrainers] = useState([
        {
            img:'',
            fullName_en: '',
            fullName_ru: '',
            fullName_am: '',
            profession_en: '',
            profession_ru: '',
            profession_am: '',
        }
    ])
    useEffect(() => {
        axios.get('/lessons/getLessonTitles')
            .then(response => {
                setLessonsTitle(response.data);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });

    }, []);
    useEffect(() => {
        axios.get('quizz/getAll')
            .then(response => {
                setQuizz(response.data);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });

    }, []);
    const [files,setFiles]= useState([])

    const handleChange = (e) => {
        const file = e.target.files?.[0];
        if (file) {
            setFiles([file]);
            const reader = new FileReader();

            reader.readAsDataURL(file);
        }
    }

    function handleRemove(index) {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    }

    // const [files1,setFiles1]= useState([])
    //
    // const handleTrainerImageChange = (event, index) => {
    //     const selectedFiles = Array.from(event.target.files);
    //     const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    //     const maxFileSize = 5000000;
    //
    //     const validFiles = selectedFiles.filter(
    //         file => allowedTypes.includes(file.type) && file.size <= maxFileSize,
    //     );
    //
    //     if (validFiles.length > 0) {
    //         const updatedTrainers = [...trainers];
    //         updatedTrainers[index].img = validFiles[0];
    //         setTrainers(updatedTrainers);
    //     }
    // };

    const handleRemoveTrainerImage = (index) => {
        const updatedTrainers = [...trainers];
        updatedTrainers[index].img = null;
        setTrainers(updatedTrainers);
    }
    const onChanges = () => {
        onHandleSend()
    }

    useEffect(() => {
        axios.get('/group/getTeachers')
            .then(response => {
                setTeacherList(response.data.users);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });


    }, []);

    const handlePriceChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setPrice(inputValue);
        }
    };
    const handleSaleChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setSales(inputValue);
        }
    };

    const handleAddPrerequisite = () => {
        setMoreDetails(prevDetails => [
            ...prevDetails,
            {
                whom:'',
                whomrus:'',
                whomarm:'',
                text:'',
                textrus:'',
                textarm:'',
            }
        ]);
    };

    const handleMoreDetailsChange = (index, field, value) => {
        const updatedDetails = [...moreDetails];
        updatedDetails[index][field] = value;
        setMoreDetails(updatedDetails);
    };
    const handleAddTrainer = () => {
        setTrainers(prevTrainers => [
            ...prevTrainers,
            {
                img: '',
                fullName_en: '',
                fullName_ru: '',
                fullName_am: '',
                profession_en: '',
                profession_ru: '',
                profession_am: '',
            }
        ]);
    };

    const handleTrainerChange = (index, field, value) => {
        const updatedTrainers = [...trainers];
        updatedTrainers[index][field] = value;
        setTrainers(updatedTrainers);
    };
    const handleRemoveMoreDetail = (index) => {
        const updatedDetails = [...moreDetails];
        updatedDetails.splice(index, 1);
        setMoreDetails(updatedDetails);
    };
    const handleRemoveTrainer = (index) => {
        const updatedTrainers = [...trainers];
        updatedTrainers.splice(index, 1);
        setTrainers(updatedTrainers);
    };
    const onFileChange = (e,index) => {
        const file = e.target.files?.[0];
        if (file) {
            const updatedTrainers = [...trainers];
            updatedTrainers[index].img = file;
            setTrainers(updatedTrainers);
            const reader = new FileReader();

            reader.readAsDataURL(file);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setWhyThisCourse(whyThisCourse + '\n• ');
        }
    };
    const handleKeyPressru = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setWhyThisCourserus(whyThisCourserus + '\n• ');
        }
    };
    const handleKeyPressam = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setWhyThisCoursearm(whyThisCoursearm + '\n• ');
        }
    };


    const onHandleSend = async () => {
        const formData = new FormData();

        const levelData = moreDetails.map(detail => ({
            title: detail.whom,
            description: detail.text,
        }));
        const levelDataru = moreDetails.map(detail => ({
            title: detail.whomrus,
            description: detail.textrus,
        }));
        const levelDataam = moreDetails.map(detail => ({
            title: detail.whomarm,
            description: detail.textarm,
        }));

        const whyParticipateArray = whyThisCourse
            .split('\n')
            .map(line => line.trim().replace('• ', ''))
            .filter(line => line !== '');
        const whyParticipateArrayru = whyThisCourserus
            .split('\n')
            .map(line => line.trim().replace('• ', ''))
            .filter(line => line !== '');
        const whyParticipateArrayam = whyThisCoursearm
            .split('\n')
            .map(line => line.trim().replace('• ', ''))
            .filter(line => line !== '');

        formData.append('levelDescriptions_en', JSON.stringify(levelData));
        formData.append('levelDescriptions_ru', JSON.stringify(levelDataru));
        formData.append('levelDescriptions_am', JSON.stringify(levelDataam));
        formData.append('whyThisCourse_en', JSON.stringify(whyParticipateArray));
        formData.append('whyThisCourse_ru', JSON.stringify(whyParticipateArrayru));
        formData.append('whyThisCourse_am', JSON.stringify(whyParticipateArrayam));
        formData.append(`img`, files[0]);
        formData.append('title_en', coursName);
        formData.append('title_ru', coursNameRu);
        formData.append('title_am', coursNameAm);
        formData.append('priceTitle', individualTitle);
        formData.append('priceDescription', individualDescription);
        formData.append('duration', individualDuration);
        formData.append('maxQuizzPoint', maxQuizPoints);
        formData.append('maxHomeworkPoint', maxHomeworkPoints);
        formData.append('maxInterviewPoint', maxInterviewPoints);
        formData.append('price', price);
        formData.append('discount', sales);
        formData.append('teachers', teacher.map(t => t.id));
        formData.append('description_en', desc);
        formData.append('description_ru', descru);
        formData.append('description_am', descarm);
        formData.append('courseType_en', selectCountry);
        formData.append('courseType_ru', selectCountry);
        formData.append('courseType_am', selectCountry);
        formData.append('lessonType_en', selectFormat);
        formData.append('lessonType_ru', selectFormat);
        formData.append('lessonType_am', selectFormat);
        formData.append('quizzId', selectedQuizz.id);
        formData.append('level_en', selectLevel);
        formData.append('level_ru', selectLevel);
        formData.append('level_am', selectLevel);
        formData.append('shortDescription_en', motivationDescription);
        formData.append('shortDescription_ru', motivationDescriptionRu);
        formData.append('shortDescription_am', motivationDescriptionAm);
        formData.append('trainers', JSON.stringify(trainers));
        trainers.forEach(t => {
            formData.append('trainersImages', t.img);
        });

        selectedItems.forEach(item => {
            formData.append('lessons', item);
        });


        try {
            await axios.post('/courses/createCourse', formData);
            setErros(true);
        } catch (error) {
            setErros(false);
        }
        setModalOp(prevState => !prevState);
    };
    const [selectedButton, setSelectedButton] = useState('ENG');
    const handleClick = (button) => {
        setSelectedButton(button);
    };
    return (
        <div>
            <p className={styles.tit}>Create a new course</p>
            <div className={styles.btndiv}>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ENG' ? styles.selected : ''}`}
                    onClick={() => handleClick('ENG')}
                >
                    ENG
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ARM' ? styles.selected : ''}`}
                    onClick={() => handleClick('ARM')}
                >
                    ARM
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'RUS' ? styles.selected : ''}`}
                    onClick={() => handleClick('RUS')}
                >
                    RUS
                </button>

            </div>
            <div className={styles.cont}>
                {selectedButton === 'ENG' && <div className={styles.inpDiv}>
                    <p className={styles.name}>Course name</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={coursName}
                               onChange={(e) => setCoursName(e.target.value)}
                               placeholder={'The name of the course'}/>
                    </div>
                </div>}
                {selectedButton === 'RUS' && <div className={styles.inpDiv}>
                    <p className={styles.name}>Название курса</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={coursNameRu}
                               onChange={(e) => setCoursNameRu(e.target.value)}
                               placeholder={'Название курса'}/>
                    </div>
                </div>}
                {selectedButton === 'ARM' && <div className={styles.inpDiv}>
                    <p className={styles.name}>Դասընթացի անվանումը</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={coursNameAm}
                               onChange={(e) => setCoursNameAm(e.target.value)}
                               placeholder={'Դասընթացի անվանումը'}/>
                    </div>
                </div>}
                {selectedButton === 'ENG' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Short description</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={motivationDescription}
                               onChange={(e) => setMotivationDescription(e.target.value)}
                               placeholder={'The short description'}/>
                    </div>
                </div>}
                {selectedButton === 'RUS' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Краткое описание</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={motivationDescriptionRu}
                               onChange={(e) => setMotivationDescriptionRu(e.target.value)}
                               placeholder={'Краткое описание'}/>
                    </div>
                </div>}
                {selectedButton === 'ARM' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Համառոտ նկարագիր</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={motivationDescriptionAm}
                               onChange={(e) => setMotivationDescriptionAm(e.target.value)}
                               placeholder={'Համառոտ նկարագիր'}/>
                    </div>
                </div>}
                {selectedButton === 'ARM' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Դասընթացի նկարագրություն</p>
                    <div className={styles.controler}>
                        <textarea className={styles.area} value={descarm} onChange={event => setDescarm(event.target.value)}
                                  placeholder={'Դասընթացի նկարագրություն'}/>
                    </div>
                </div>}
                {selectedButton === 'RUS' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Описание курса</p>
                    <div className={styles.controler}>
                        <textarea className={styles.area} value={descru}
                                  onChange={event => setDescru(event.target.value)}
                                  placeholder={'Описание курса'}/>
                    </div>
                </div>}
                {selectedButton === 'ENG' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Course description</p>
                    <div className={styles.controler}>
                        <textarea className={styles.area} value={desc}
                                  onChange={event => setDesc(event.target.value)}
                                  placeholder={'Write short description of the course'}/>
                    </div>
                </div>}
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Course image</p>
                    <div className={styles.controler}>
                        <img className={styles.img} src={'/images/imgicons.png'} alt={'cube'}/>

                        {files.length > 0 ? (
                            <ul className={styles.ul}>
                                {files.map((file, index) => (
                                    <li className={styles.li} key={file.name}>
                                        {file.name}
                                        <img
                                            className={styles.images}
                                            src="/images/closeFilter.png"
                                            onClick={() => handleRemove(index)}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (<label className={styles.lil} htmlFor="ownPost">
                            <input
                                id="ownPost"
                                className={styles.file}
                                multiple
                                onChange={handleChange}
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                            />
                            Upload an image </label>)}
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Max homework points</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={maxHomeworkPoints}
                               onChange={(e) => setMaxHomeworkPoints(e.target.value.replace(/[^0-9]/g, ''))}
                               placeholder={'Max homework points'}/>
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Max quiz points</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={maxQuizPoints}
                               onChange={(e) => setMaxQuizPoints(e.target.value.replace(/[^0-9]/g, ''))}
                               placeholder={'Max quiz points'}/>
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Max interview points</p>
                    <div className={styles.controler}>
                        <input className={styles.input} type={'text'} value={maxInterviewPoints}
                               onChange={(e) => setMaxInterviewPoints(e.target.value.replace(/[^0-9]/g, ''))}
                               placeholder={'Max interview points'}/>
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Type</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Choose the type of the course'}
                                    type="text"
                                    value={selectCountry}
                                    readOnly={true}
                                    onClick={() => setOpenCountry(!openCountry)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openCountry ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}/>
                        </div>
                        {openCountry && (
                            <SelctorForCourses arr={type} value={(selectedCountry) => setSelectCountry(selectedCountry)}
                                               setOpen={setOpenCountry}/>
                        )}
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Format</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Choose the format of the course'}
                                    type="text"
                                    value={selectFormat}
                                    readOnly={true}
                                    onClick={() => setOpenFormat(!openFormat)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openFormat ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}/>
                        </div>
                        {openFormat && (
                            <SelctorForCourses arr={format} value={(selectedFormat) => setSelectFormat(selectedFormat)}
                                               setOpen={setOpenFormat}/>
                        )}
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Level</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Choose the level of the course'}
                                    type="text"
                                    value={selectLevel}
                                    readOnly={true}
                                    onClick={() => setOpenLevel(!openLevel)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openLevel ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}/>
                        </div>
                        {openLevel && (
                            <SelctorForCourses arr={level} value={(selectedLevel) => setSelectLevel(selectedLevel)}
                                               setOpen={setOpenLevel}/>
                        )}
                    </label>
                </div>
                {selectCountry === 'Individual' && (
                    <>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Title</p>
                            <div className={styles.controler}>
                                <input
                                    type={'text'}
                                    className={styles.input}
                                    placeholder={'Title'}
                                    value={individualTitle}
                                    onChange={(e)=> setIndividualTitle(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Description</p>
                            <div className={styles.controler}>
                                <input
                                    type={'text'}
                                    className={styles.input}
                                    placeholder={'Description'}
                                    value={individualDescription}
                                    onChange={(e)=> setIndividualDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Duration</p>
                            <div className={styles.controler}>
                                <input
                                    type={'text'}
                                    className={styles.input}
                                    placeholder={'Type the price of the course'}
                                    value={individualDuration}
                                    onChange={(e)=> setIndividualDuration(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Price</p>
                            <div className={styles.controler}>
                                <input
                                    type={'text'}
                                    className={styles.input}
                                    placeholder={'Type the price of the course'}
                                    value={price}
                                    onChange={handlePriceChange}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Sale</p>
                            <div className={styles.controler}>
                                <input
                                    type={'text'}
                                    className={styles.input}
                                    placeholder={'Type the discount in percent'}
                                    value={sales}
                                    onChange={handleSaleChange}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Teachers</p>
                            <label className={styles.label}>
                                <div className={styles.controler}>
                                    <div>
                                        <input
                                            className={styles.input}
                                            style={{cursor: 'pointer'}}
                                            placeholder={'Choose teachers'}
                                            type="text"
                                            value={teacher.map(t => t.title).join(', ')}
                                            readOnly={true}
                                            onClick={() => setOpen5(!open5)}
                                        />
                                    </div>
                                    <RiArrowDownSLine
                                        className={!open5 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                                    />
                                </div>
                                {open5 &&
                                    <CheckBoxMulty
                                        arr={teacherList}
                                        value={(e) => setTeacher(e)}
                                        setOpen={setOpen5}
                                        displayId={true}
                                        selectedItems={teacher}
                                    />
                                }
                            </label>
                        </div>
                    </>
                )
                }
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Add lessons</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Add lessons for this quiz'}
                                    type="text"
                                    value={selectedItems.length >= 1 ? selectedItems.length + " " + "lessons have been selected" : 'Add lessons for this course'}
                                    readOnly={true}
                                    onClick={() => setOpen(!open)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openLevel ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />
                        </div>
                        {open &&
                            <CourseChackbox
                                arr={lessonsTitle ? lessonsTitle : []}
                                value={(e) => setSelectedItems(e)}
                                setOpen={setOpen}
                                displayId={true}
                                selectedItems={selectedItems}
                            />
                        }
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Add Final Quiz</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div style={{width: "100%"}}>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Add quiz for this course'}
                                    type="text"
                                    value={selectedQuizz.title}
                                    readOnly={true}
                                    onClick={() => setOpenFormat1(!openFormat1)}
                                />
                            </div>

                            <RiArrowDownSLine
                                className={!openFormat1 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />

                        </div>
                        {openFormat1 &&
                            <AssignIDType
                                arr={quizz ? quizz : []}
                                value={(e) => setSelectedQuizz(e)}
                                setOpen={setOpenFormat1}
                                displayId={true}
                            />
                        }
                    </label>
                </div>
                <div className={styles.inpDiv}>
                    {selectedButton === 'ENG' &&  <p className={styles.detal}>Add more details</p>}
                    {selectedButton === 'RUS' &&  <p className={styles.detal}>Добавить больше деталей</p>}
                    {selectedButton === 'ARM' &&  <p className={styles.detal}>Ավելացնել ավելի շատ մանրամասներ</p>}
                </div>
                { selectedButton === 'ENG' &&   <div className={styles.inpDiv}>
                    <p className={styles.name}>Why participate in this course?</p>
                    <div className={styles.controler}>
                        <textarea
                            className={styles.input}
                            value={whyThisCourse}
                            onChange={e => setWhyThisCourse(e.target.value)}
                            placeholder={'Type with points. (Alt + 0149)'}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                </div>}
                 {  selectedButton === 'RUS' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Зачем участвовать в этом курсе?</p>
                    <div className={styles.controler}>
                        <textarea
                            className={styles.input}
                            value={whyThisCourserus}
                            onChange={e => setWhyThisCourserus(e.target.value)}
                            placeholder={'Набирайте текст точками.'}
                            onKeyPress={handleKeyPressru}

                        />
                    </div>
                </div>}
                { selectedButton === 'ARM' &&  <div className={styles.inpDiv}>
                    <p className={styles.name}>Ովքեր են մասնակցում այս դասընթացին:</p>
                    <div className={styles.controler}>
                        <textarea
                            className={styles.input}
                            value={whyThisCoursearm}
                            onChange={e => setWhyThisCoursearm(e.target.value)}
                            placeholder={'Ովքեր են մասնակցում այս դասընթացին:'}
                            onKeyPress={handleKeyPressam}

                        />
                    </div>
                </div>}
                {moreDetails.map((detail, index) => (
                    <div style={{width: '100%'}} key={index}>
                        <div className={styles.inpDiv}>
                            {selectedButton === 'ENG' && <p className={styles.name}>For whom is this course for?</p>}
                            {selectedButton === 'RUS' &&  <p className={styles.name}>Для кого предназначен этот курс?</p>}
                            {selectedButton === 'ARM' &&  <p className={styles.name}>Ում համար է այս դասընթացը:</p>}
                            { selectedButton === 'ENG' &&  <div className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.whom}
                                    onChange={e => handleMoreDetailsChange(index, 'whom', e.target.value)}
                                    placeholder={'Type the level'}
                                />
                            </div>}
                            { selectedButton === 'RUS' &&  <div className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.whomrus}
                                    onChange={e => handleMoreDetailsChange(index, 'whomrus', e.target.value)}
                                    placeholder={'Введите уровень'}
                                />
                            </div>}
                            { selectedButton === 'ARM' &&  <div className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.whomarm}
                                    onChange={e => handleMoreDetailsChange(index, 'whomarm', e.target.value)}
                                    placeholder={'Մուտքագրեք մակարդակը'}
                                />
                            </div>}
                            { selectedButton === 'ENG' &&  <div style={{marginTop: '20px'}} className={styles.controler}>
                                <textarea
                                    className={styles.input}
                                    // type={'text'}
                                    value={detail.text}
                                    onChange={e => handleMoreDetailsChange(index, 'text', e.target.value)}
                                    placeholder={'Type the description'}
                                />
                            </div>}
                            { selectedButton === 'RUS' &&  <div style={{marginTop: '20px'}} className={styles.controler}>
                                <textarea
                                    className={styles.input}
                                    // type={'text'}
                                    value={detail.textrus}
                                    onChange={e => handleMoreDetailsChange(index, 'textrus', e.target.value)}
                                    placeholder={'Введите описание'}
                                />
                            </div>}
                            { selectedButton === 'ARM' &&  <div style={{marginTop: '20px'}} className={styles.controler}>
                                <textarea
                                    className={styles.input}
                                    // type={'text'}
                                    value={detail.textarm}
                                    onChange={e => handleMoreDetailsChange(index, 'textarm', e.target.value)}
                                    placeholder={'Մուտքագրեք նկարագրությունը'}
                                />
                            </div>}
                        </div>
                        <div className={styles.delete}>
                            {moreDetails.length > 1 && (
                                <button className={styles.deleteButton} onClick={() => handleRemoveMoreDetail(index)}>
                                    Delete this one
                                </button>
                            )}
                        </div>
                    </div>
                ))}
                <div className={styles.inpDiv}>
                    <button className={styles.big} onClick={handleAddPrerequisite}>Add new prerequisite</button>

                </div>
                {
                    trainers.map((trainer, index) => (
                        <div style={{width: "100%"}} key={index}>
                            <div className={styles.inpDiv}>
                                {selectedButton === 'ENG' &&  <p className={styles.name}>Add trainers</p>}
                                {selectedButton === 'RUS' &&  <p className={styles.name}>Добавить преподавателя</p>}
                                {selectedButton === 'ARM' &&  <p className={styles.name}>Ավելացնել ուսուցիչներին</p>}
                                <div className={styles.controler}>
                                    <img className={styles.img} src={'/images/imgicons.png'} alt={'cube'}/>
                                    {trainer.img ? (
                                        <div>
                                            <ul className={styles.ul}>
                                                <li className={styles.li}>
                                                    {trainer.img.name}
                                                    <img
                                                        className={styles.images}
                                                        src="/images/closeFilter.png"
                                                        onClick={() => handleRemoveTrainerImage(index)}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    ) : (
                                        <label className={styles.lil} htmlFor={`trainerImage-${index}`}>
                                            <input
                                                id={`trainerImage-${index}`}
                                                className={styles.file}
                                                onChange={(e) => onFileChange(e, index)}
                                                type="file"
                                                accept="image/png, image/jpg, image/jpeg"
                                            />
                                            Upload an image
                                        </label>
                                    )}
                                </div>
                            </div>
                            <div className={styles.container}>
                                <div className={styles.divData}>
                                    <p className={styles.name}></p>
                                    { selectedButton === 'ENG' &&  <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Teacher’s full name'}
                                            value={trainer.fullName_en}
                                            onChange={(e) => handleTrainerChange(index, 'fullName_en', e.target.value)}
                                        />
                                    </div>}
                                    { selectedButton === 'RUS' &&  <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Имя преподавателя'}
                                            value={trainer.fullName_ru}
                                            onChange={(e) => handleTrainerChange(index, 'fullName_ru', e.target.value)}
                                        />
                                    </div>}
                                    { selectedButton === 'ARM' &&  <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Ուսուցչի  անվանումը'}
                                            value={trainer.fullName_am}
                                            onChange={(e) => handleTrainerChange(index, 'fullName_am', e.target.value)}
                                        />
                                    </div>}
                                </div>
                                <div className={styles.divData}>
                                    <p className={styles.name}></p>
                                    { selectedButton === 'ENG' &&  <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Teacher’s qualification'}
                                            value={trainer.profession_en}
                                            onChange={(e) => handleTrainerChange(index, 'profession_en', e.target.value)}
                                        />
                                    </div>}
                                    { selectedButton === 'RUS' &&  <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Квалификация преподавателя'}
                                            value={trainer.profession_ru}
                                            onChange={(e) => handleTrainerChange(index, 'profession_ru', e.target.value)}
                                        />
                                    </div>}
                                    { selectedButton === 'ARM' &&  <div className={styles.controler}>
                                        <input
                                            type={'text'}
                                            className={styles.input}
                                            placeholder={'Ուսուցչի որակավորումը'}
                                            value={trainer.profession_am}
                                            onChange={(e) => handleTrainerChange(index, 'profession_am', e.target.value)}
                                        />
                                    </div>}
                                </div>
                            </div>
                            <div className={styles.delete}>
                                {trainers.length > 1 && <button className={styles.deleteButton}
                                                                onClick={() => handleRemoveTrainer(index)}>Delete this
                                    one</button>
                                }
                            </div>

                        </div>
                    ))
                }
                <div className={styles.inpDiv}>
                    <button className={styles.big} onClick={handleAddTrainer}>Add new trainer</button>
                </div>
                <div className={styles.btns}>
                    <Button handleButtonClick={onChanges}
                            disabled={trainers.find(trainer => !trainer.profession_ru.trim().length)}
                            className={'bigPrimary'} text={'Create a new course'}/>
                </div>
            </div>
            {modalOp && <AdminModal link={'/admin/courses?isNew=true'} setOpen={setModalOp}
                                    question={erros ? (`${coursName} course has been successfully created.`) : 'Something went wrong'}
                                    boxShadow/>
            }
        </div>
    );
}

export default CreateNewCourseAdmin;