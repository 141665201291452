import React from "react";
import styles from './experts.module.css'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Experts({image}) {

    const {t, i18n} = useTranslation()

    return(
        <div className={styles.container}>
            {image && <img src='/images/heartGirl.png' className={styles.heart} alt='heart'/>}
            <div className={styles.cont}>
                <p className={`${styles.head} ${styles[`head_${i18n.language}`]}`}>
                    {t('homepage.ourExperts.title')}
                </p>
                <p className={styles.text}>{t('homepage.ourExperts.firstDescription')}</p>
                <p className={styles.text}>{t('homepage.ourExperts.secondDescription')}</p>
            </div>
                <img src='/images/DavidGrigoryan.jpg' className={styles.image} alt={'David'}/>
            <img src='/images/LusineHovhannisyan.jpg' className={styles.image} alt={'Lusine'}/>
            <Link className={styles.link} to={'/company/about'}>
                <div className={styles.about}>
                    <p className={styles.innertext}>{t('navigation.aboutUs')}</p>
                </div>
            </Link>
        </div>
    )
}

export default Experts;