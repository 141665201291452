import React, {useState} from 'react';
import styles from './paymentMethods.module.css';
import Button from '../../../Components/./Button/button';
import TextHighlighter from "../../../Components/TextHighlighter/TextHighlighter";
import PayingModal from "../PayingModal/PayingModal";
import {useNavigate, useParams} from "react-router-dom";
import {useUserCourseContext} from "../../../Components/UserCourseContext/UserCourseContext";
import {ROLES, useUserContext} from "../../../Components/UserContext/UserContext";
import Modal from "../../../Components/Modal/Modal";
import {useTranslation} from "react-i18next";

const PaymentMethods = ({payment, courseType}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [isBought, setIsBought] = useState(false)
    const [isNotLoggedIn, setIsNotLoggedIn] = useState(false)
    const [isNotStudent, setIsNotStudent] = useState(false)
    const [paymentData, setPaymentData] = useState({paymentId: null, courseType: null })
    const {id} = useParams()
    const {user} = useUserContext()
    const navigate = useNavigate()
    const {boughtCourses} = useUserCourseContext()
    const {t, i18n} = useTranslation()

    const handleGroupBuy = (paymentId, courseType) => {
        if(!user) {
            setIsNotLoggedIn(true)
        } else if(user && boughtCourses?.map(course => course.id).includes(+id)) {
            setIsBought(true)
        } else if(user && user?.role !== ROLES.STUDENT) {
            setIsNotStudent(true)
        } else {
            setIsOpen(true)
            setPaymentData({paymentId: paymentId, courseType: courseType})
        }
    }

  return (
      <>
        <div className={styles.paymentMethods} id={'payment'}>
          <div className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>
              {t('courseSinglePage.paymentMethods.title')}
          </div>
          <div className={payment.length > 1 ? styles.inner : styles.innerOneMethod}>
              {payment?.map((method, i) => {
                  return (
                      <div className={`${styles.method} ${payment.length === 1 ? styles.methodFlex : ''}`} key={i}>
                          <p>{t(`courseSinglePage.paymentMethods.paymentWays.${method.title.toLowerCase()}`)}</p>
                          <span>{method.description}</span>
                          <span className={styles.price}>{method.discount === 0 ? method.price : (Math.round(method.price - (method.price * method.discount)/100))}<span className={styles.currency}>֏</span></span>
                          <div className={styles.priceCont}>
                              {method.discount !== 0 &&
                                  <>
                                      <p className={styles.discount}>-{Math.round(method.discount)}%</p>
                                      <p className={styles.initialPrice}>{method.price}</p>
                                      <p className={styles.saleCurrency}>֏</p>
                                  </>
                              }
                          </div>
                          <Button
                              text={`${t(`courseSinglePage.paymentMethods.paymentWays.${method.title.toLowerCase()}`)}`}
                              className={'primary'}
                              styles={{padding: '10px 35px'}}
                              handleButtonClick={() => handleGroupBuy(method?.id, courseType)}
                          />
                      </div>
                  )
              })}
          </div>
        </div>
        {isOpen &&
          <PayingModal
              setOpen={setIsOpen}
              paymentData={paymentData}
              payment={payment}
          />
        }
          {isNotLoggedIn &&
              <Modal
                  question={t('courseSinglePage.paymentMethods.wantBuyACourse')}
                  description={t('courseSinglePage.paymentMethods.notLoggedIn')}
                  onConfirm={() => navigate('/login')}
                  confirmText={t('logIn.logIn')}
                  setOpen={setIsNotLoggedIn}
                  closeText={t('frequentlyUsedExpressions.exit')}
                  onClose={() => setIsNotLoggedIn(false)}
              />
          }
          {isBought &&
              <Modal
                  question={t('courseSinglePage.paymentMethods.wantBuyACourse')}
                  description={t('courseSinglePage.paymentMethods.alreadyBought')}
                  onConfirm={() => setIsBought(false)}
                  confirmText={t('frequentlyUsedExpressions.understand')}
                  setOpen={setIsBought}
              />
          }
          {isNotStudent &&
              <Modal
                  question={t('courseSinglePage.paymentMethods.wantBuyACourse')}
                  description={`As ${user?.role === ROLES.TEACHER ? 'a' : 'the'} ${user?.role.toLowerCase()}, you are unable to purchase courses.`}
                  onConfirm={() => setIsNotStudent(false)}
                  confirmText={t('frequentlyUsedExpressions.understand')}
                  setOpen={setIsNotStudent}
              />
          }
      </>
  );
};

export default PaymentMethods;
