import React, { useEffect, useState, useTransition } from "react";
import styles from './admindashboard.module.css'
import CoursesInProgress from "../../TeacherDashboard/Teacher/CoursesInProgress/CoursesInProgress";
// import HomeworkProgress from "../../TeacherDashboard/Teacher/HomeworkProgress/HomeworkProgress";
// import AdminChart from "./AdminChart/AdminChart";
import DashCalendar from "../../Dashboard/Home/Calendar/DashCalendar";
// import TopStudents from "../../TeacherDashboard/Teacher/TopStudents/TopStudents";
import CreateNew from "./CreateNew/CreateNew";
import axios from "../../axios/axios";
import { useTranslation } from "react-i18next";

function AdminDashboard() {

    const [statistics, setStatistics] = useState(null);
    const { i18n } = useTranslation()

    useEffect(() => {
        axios.get('/dashboard/getAdminStatistics').then(({data}) => setStatistics(data));
    }, [])

    return(
        <section className={styles.section}>
            <div className={styles.container + ' ' + styles.first}>
                <CoursesInProgress />
                {/*<HomeworkProgress />*/}
                <div className={styles.section}>
                    <CreateNew />
                    {/*<TopStudents />*/}
                </div>
            </div>
            <div className={styles.container + ' ' + styles.second}>
                <div>
                    <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>Statistics</p>
                    <div className={styles.statistics}>
                        <div className={styles.countContainer}>
                            <p>Groups</p>
                            <p>{statistics?.groupsCount}</p>
                        </div>
                        <div className={styles.countContainer}>
                            <p>Students</p>
                            <p>{statistics?.studentsCount}</p>
                        </div>
                        <div className={styles.countContainer}>
                            <p>Teachers</p>
                            <p>{statistics?.teachersCount}</p>
                        </div>
                    </div>
                </div>
                <DashCalendar />
            </div>
        </section>
    )
}

export default AdminDashboard;