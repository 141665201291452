import React, {useEffect} from "react";
import styles from './paymentmessage.module.css'
import RegPage from "../../Registration/RegPage/RegPage";
import Button from "../../../Components/Button/button";
import {useLocation, useNavigate} from "react-router-dom";
import {ROLES, useUserContext} from "../../../Components/UserContext/UserContext";
import axios from "../../../axios/axios";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function PaymentSuccess() {

    const {user} = useUserContext()
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const orderId = searchParams.get('orderId')
    const {t} = useTranslation()

    useEffect(() => {
        if(user?.role === ROLES.ADMIN) {
            axios.post('/payment/paymentArcaForAdmin', {
                orderKey: orderId
            })
        } else {
            axios.post('/payment/buy', {
                orderKey: orderId
            })
        }
    }, [orderId])

    const handleDownloadInvoice = () => {
        axios.get(`/payment/downloadInvoice`, {
            params: { orderId },
            responseType: 'blob'
        }).then(({ data }) => {
            if (data.size === 0) {
                return;
            }
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            const filename = `Payment_Invoice.pdf`;

            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log("Error downloading invoice:", error);
        });
    };

    return (
        <>
            <Helmet>
                <title>Payment Success - Thank You for Your Purchase</title>
                <meta name="description" content="Thank you for your purchase. Your payment was successful, and your transaction is complete." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <RegPage
                title={t('payment.paymentStatus.title')}
                img={'/images/successGirl.png'}
                className={styles.image}
                content={
                    <div className={`${styles.container} ${styles.successGap}`}>
                        <img src={'/images/congratulation.png'} className={styles.img} alt={'congratulation'}/>
                        <p className={styles.title}>{t('payment.paymentStatus.success.confirmationMessage')}</p>
                        <p className={styles.text}>
                            {t('payment.paymentStatus.success.text')}
                        </p>
                        <div className={styles.invoiceCont}>
                            <Button
                                className={'bigPrimary'}
                                text={t('payment.paymentStatus.success.goToPayments')}
                                handleButtonClick={() => navigate(`/${user.role.toLowerCase()}/payment`)}
                            />
                            <Button
                                className={'bigPrimary'}
                                text={t('payment.paymentStatus.downloadInvoice')}
                                handleButtonClick={handleDownloadInvoice}
                            />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default PaymentSuccess;