import React, { useEffect, useState } from "react";
import styles from "../../Dashboard/Payment/paymentTable.module.css";
import axios from "../../axios/axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Button from "../../Components/Button/button";
import {useUserContext} from "../../Components/UserContext/UserContext";
function Subscriptions() {
    const [payment, setPayment] = useState(null);
    const [paymentType, setPaymentType] = useState();
    const [paymentWay, setPaymentWay] = useState('ARCA');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();
    const {user} = useUserContext();

    useEffect(() => {
        const fetchPayments = async () => {
            try {
                const { data: adminData } = await axios.get('/payment/getAdminPayment');
                const { data: nextPaymentData } = await axios.get('/payment/nextPaymentAdmin');
                const nextPaymentDate = nextPaymentData?.nextPaymentDate; 
                setPayment({
                    adminPayments: adminData?.adminPayments || [],
                    nextPaymentDate: nextPaymentDate || null, 
                });
            } catch (error) {
                setPayment(null);
            }
        };
        fetchPayments();
    }, []);

    const paymentWays = [
        {
            name: t('courseSinglePage.paymentMethods.paymentWays.monthly'),
            en_version: 'monthly'
        },
        {
            name: t('courseSinglePage.paymentMethods.paymentWays.full'),
            en_version: 'full'
        }
    ]

    const handlePayment = type => {
        setPaymentType(type);
        setIsModalOpen(true);
    }

    const handleAdminPay = () => {
        if (paymentWay === 'ARCA') {
            axios.post('payment/paymentUrlForAdmin', {
                paymentWay,
                creatorId: user?.creatorId,
                type: paymentType
            }).then(({ data }) => window.open(data?.formUrl, '_blank'))
        } else if (paymentWay === 'IDRAM') {
            axios.post('payment/paymentUrlForAdmin', {
                paymentWay,
                creatorId: user?.creatorId,
                type: paymentType
            }).then(({ data }) => {
                const form = document.createElement('form');
                form.method = 'POST';
                form.action = 'https://banking.idram.am/Payment/GetPayment';

                const params = {
                    EDP_LANGUAGE: "EN",
                    EDP_REC_ACCOUNT: "100046705",
                    EDP_DESCRIPTION: `Payment for Admin.`,
                    EDP_AMOUNT: data?.amount,
                    EDP_BILL_NO: data?.id
                };

                Object.keys(params).forEach(key => {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = key;
                    input.value = params[key];
                    form.appendChild(input);
                });

                document.body.appendChild(form);
                form.submit();
                document.body.removeChild(form);
            })
        }
    }

    return (
        <>
            {isModalOpen && (
                <div className={styles.modalContainer} onClick={() => setIsModalOpen(false)}>
                    <div className={`${styles.modal} ${styles.boxShadow}`} onClick={e => e.stopPropagation()}>
                        <div className={styles.cardBlocks}>
                            <div className={`${styles.method}  ${paymentWay === 'ARCA' ? styles.selected : ''}`} onClick={() => setPaymentWay('ARCA')}>
                                <img src={'/images/card.png'} className={styles.methodImg} alt={'card'} />
                                <p className={styles.methodName}>{t('payment.cards')}</p>
                            </div>
                            <div className={`${styles.method} ${paymentWay === 'IDRAM' ? styles.selected : ''}`} onClick={() => setPaymentWay('IDRAM')}>
                                <img src={'/images/idramIcon.png'} className={styles.methodImg} alt={'idram'} />
                                <p className={styles.methodName}>{t('payment.Idram')}</p>
                            </div>
                        </div>
                        <Button
                            text={paymentWay === 'ARCA' ? `${t('payment.pay')}` : t('payment.payWithIdram')}
                            className={'bigPrimary'}
                            handleButtonClick={handleAdminPay}
                        />
                    </div>
                </div>
            )}
            <p className={styles.title}>{t('paymentDetails.title')}</p>
            <div className={styles.courseNamesContainer}>
            </div>
            {payment?.adminPayments ? (
                <div className={styles.paymentDetails}>
                    <p className={styles.boldTitle}>{t('paymentDetails.details')}</p>
                    <div className={styles.monthlyPayment}>
                        <div className={styles.calendarContainer}>
                            <img
                                src="/images/calendarLink.png"
                                className={styles.calendar}
                                alt="calendar"
                            />
                            <div>
                                <p className={styles.boldTitle}>
                                    {payment?.responsData?.payments[0]?.type === 'monthly'
                                        ? t('paymentDetails.monthly')
                                        : t('paymentDetails.full')
                                    }&nbsp;
                                    {t('paymentDetails.payment')}
                                </p>
                                {payment?.nextPaymentDate && (
                                    <div className={styles.courseNamesContainer}>
                                        <p className={`${styles.next} ${styles.red}`}>
                                            {t('paymentDetails.nextPayment')}
                                        </p>
                                        <p className={`${styles.next} ${styles.grey}`}>
                                            {t('paymentDetails.nextPaymentDate', {
                                                date: moment(payment?.nextPaymentDate).format("DD MMM, YYYY"),
                                                amount: payment?.responsData?.userUnpaidSum
                                            })}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.containerButton}>
                            {paymentWays?.map((method, id) => (
                                <Button
                                    key={id}
                                    text={method.name}
                                    className="primary"
                                    styles={{ width: "300px" }}
                                    handleButtonClick={() => handlePayment(method?.en_version)}
                                />
                            ))}
                         </div>
                    </div>
                </div>
            ) : (
                <>
                <p className={styles.noPayment}>{t('paymentDetails.noPayment')}</p>
                <div className={styles.buttonsContainer}>
                    {paymentWays?.map((method, id) => (
                        <Button
                            key={id}
                            text={method.name}
                            className="primary"
                            styles={{ width: "300px" }}
                            handleButtonClick={() => handlePayment(method?.en_version)}
                        />
                    ))}
                </div>
            </>
            )}
            {payment?.adminPayments && (
                <div className={styles.paymentDetails}>
                    <div className={styles.row}>
                        <p className={styles.columnTitle}>{t('paymentDetails.method')}</p>
                        <p className={styles.columnTitle}>{t('paymentDetails.billingDate')}</p>
                        <p className={styles.columnTitle}>{t('paymentDetails.amount')}</p>
                        <p className={styles.columnTitle}>{t('paymentDetails.type')}</p>
                        <p className={styles.columnTitle}>{t('paymentDetails.status')}</p>
                    </div>
                    <div className={styles.rowsContainer}>
                        {payment?.adminPayments?.map(perPayment => (
                            <div className={styles.row} key={perPayment?.id}>
                                <p className={styles.boldTitle}>{perPayment.paymentWay}</p>
                                <p className={styles.boldTitle}>
                                    {moment(perPayment.createdAt).format("DD MMM, YYYY")}
                                </p>
                                <p className={styles.boldTitle}>
                                    {perPayment.amount} AMD
                                </p>
                                <p className={styles.boldTitle}>
                                {t(`courseSinglePage.paymentMethods.paymentWays.${perPayment.type}`)}
                                </p>
                                <div
                                    className={`${styles.status} ${
                                        perPayment.status === "Success"
                                            ? styles.paid
                                            : styles.failed
                                    }`}
                                >
                                    {perPayment.status === "Success"
                                        ? t('paymentDetails.paid')
                                        : t('paymentDetails.failed')}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export default Subscriptions;
