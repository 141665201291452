import React from "react";
import styles from './user.module.css'
import {useUserCourseContext} from "../../../Components/UserCourseContext/UserCourseContext";
import {ROLES, useUserContext} from "../../../Components/UserContext/UserContext";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
function User({userStatics}) {

    const {user} = useUserContext();
    const {boughtCourses} = useUserCourseContext();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const groupId = searchParams.get('groupId');
    const groupName = boughtCourses?.find(group => group.id === +groupId)?.title;
    const {t, i18n} = useTranslation();
    const groupLevel = user.role === ROLES.STUDENT ? userStatics?.lessonType : userStatics?.level;

    return(
            <div className={`${styles.container} ${user.role === 'TEACHER' ? styles.grey : ''}`}>
                <div style={{background: `url(${user?.image ? `https://platform.tesvan.com/server/${user?.image}` : `https://platform.tesvan.com/server/defaultIcon.png`})`}} className={styles.user} />
                <p className={`${styles.name} ${styles[`name_${i18n.language}`]}`}>{user.firstName + ' ' + user.lastName}</p>
                <p className={styles.course}>{user.role === ROLES.STUDENT ? groupName : userStatics?.name}  </p>
                <div className={styles.datacont}>
                    {user.role === ROLES.STUDENT &&
                        <>
                            <div className={styles.amountcont}>
                                <img src={'/images/groupUser.png'} className={styles.icon} alt={'group'} />
                                <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{t('frequentlyUsedExpressions.group')}</p>
                                <p className={`${styles.amount} ${styles[`amount_${i18n.language}`]}`}>{userStatics?.students} {t('frequentlyUsedExpressions.students')}</p>
                            </div>
                            <div className={styles.amountcont}>
                                <img src={'/images/lesson.png'} className={styles.icon + ' ' + styles.anIcon} alt={'lesson'}/>
                                <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{t('frequentlyUsedExpressions.lessons')}</p>
                                <p className={`${styles.amount} ${styles[`amount_${i18n.language}`]}`}>{userStatics?.lessons} {t('dashboard.lessons')}</p>
                            </div>
                            <div className={styles.amountcont}>
                                <img src={'/images/userCourseLevel.png'} className={styles.icon} alt={'level'}/>
                                <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{t('frequentlyUsedExpressions.level')}</p>
                                <p className={`${styles.amount} ${styles[`amount_${i18n.language}`]}`}>{groupLevel && t(`frequentlyUsedExpressions.${groupLevel.toLowerCase()}${groupLevel === 'Advanced' ? 'Short' : ''}`)}</p>
                            </div>
                        </>
                    }
                </div>
            </div>
    )
}

export default User;