import React, { useState, useEffect } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "../../axios/axios";
import styles from './editor.module.css';
import moment from "moment";
import {useTranslation} from "react-i18next";

function RichEditor({ value, setValue, readOnly, fileLinks = [], setFileLinks, dueDate, startDate }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const {t} = useTranslation()

    const addFileLink = (link, name, url) => {
        const newFileLinks = [...fileLinks, { link, name, url }];
        setFileLinks(newFileLinks);
    };

    const removeFileLink = (url) => {
            axios.delete(`/upload/file/${url}`)
                .then(() => {const newFileLinks = [...fileLinks];
                    newFileLinks.splice(url, 1);
                    setFileLinks(newFileLinks);});
    };

    const uploadFile = async () => {
        if (selectedFile) {
            try {
                const formData = new FormData();
                formData.append('file', selectedFile);

                const { data } = await axios.post('/upload/file', formData)

                const link = `https://platform.tesvan.com/server/${data.url}`;
              

                if (!selectedFile.type.startsWith('image')) {
                    addFileLink(link, selectedFile.name, data.url);
                } else {
                    const newValue = `${value} 
                        <img 
                            src="${link}"  
                            alt="uploaded"
                            />`;
                    setValue(newValue);
                }

                console.log('File upload response:', data);
            } catch (error) {
                alert("Couldn't upload the file.");
            }
        }
    };

    useEffect(() => {
        uploadFile();
    }, [selectedFile]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    let toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['code-block', 'link'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'color': [] }, { 'background': [] }],
        ['align'],
        [
            { 'align': 'center' },
            { 'align': 'right' },
        ],
        ['clean']
    ];

    const modules = {
        toolbar: toolbarOptions
    };

    return (
        <>
            <div className={styles.toolbar}>
                <div className={styles.dates}>
                    {startDate && <span className={styles.date}>Start date: &nbsp;{moment(startDate).format("DD.MM.YYYY")}</span>}
                    {dueDate && <span className={styles.date}>Due date: &nbsp;{moment(dueDate).format("DD.MM.YYYY")}</span>}
                </div>
                <div className={styles.container}>
                    <label>{t('homework.chooseFile')}</label>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        className={styles.file}
                        title=""
                        disabled={readOnly}
                    />
                </div>
            </div>
            <div className={styles.cont}>
                <ReactQuill
                    modules={modules}
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    placeholder={t('homework.placeholder')}
                    readOnly={readOnly}
                />
                <div className={styles.fileLinks}>
                    {fileLinks?.map((file, index) => (
                        <div key={index}>
                            <a href={file.link} target="_blank" rel="noopener noreferrer" className={styles.link}>
                                <div className={styles.filecont}>
                                    <div className={styles.checkcont}>
                                        <img src={'/images/checkGreen.png'} alt={'checkMark'} className={styles.check}/>
                                        {file.name}
                                    </div>
                                    <img
                                        src={'/images/trash.png'}
                                        alt={'trash'}
                                        className={styles.trash}
                                        onClick={e => {
                                            e.preventDefault();
                                            removeFileLink(file.url)
                                        }}
                                    />
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default RichEditor;

