import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from '../../axios/axios';
import { useUserContext } from '../../Components/UserContext/UserContext';
import { useUserCourseContext } from '../../Components/UserCourseContext/UserCourseContext';
import { ROLES } from '../../Components/UserContext/UserContext';
import Button from '../../Components/Button/button';
import Header from '../../Components/Head/Header';
import Loader from '../../Components/Loader/Loader';
import CourseCertificates from './CourseCertificates/CourseCertificates';
import CourseProgram from './CourseProgram/CourseProgram';
import ForWhomIsCourse from './ForWhomIsCourse/ForWhomIsCourse';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import StudentsSay from '../Home/students/StudentsSay';
import Trainers from './Trainers/Trainers';
import WhyThisCourse from './WhyThisCourse/WhyThisCourse';
import styles from './singleCourse.module.css';
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

const SingleCourse = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { hash } = useLocation();
  const { id } = useParams();
  const { boughtCourses } = useUserCourseContext();
  const [course, setCourse] = useState();
  const [isBought, setIsBought] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {t, i18n} = useTranslation();

  useEffect(() => {
    axios
        .get(`/courses/getOne/${id}`, { params: { language: 'en' } })
        .then(({ data }) => {
          setCourse({
            ...data,
            ...data.CoursesContents[0],
            startDate: getDate(data.startDate),
            duration: getDuration(data?.duration),
          });
          setIsLoading(false);
        })
        .catch(() => navigate({pathname: '/courses', hash: 'allCourses'}));
  }, [id, navigate]);

  useEffect(() => {
    if (hash === '#payment') {
      document.getElementById('payment')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isLoading, user, boughtCourses, hash])

  useEffect(() => {
    if (boughtCourses && boughtCourses?.map((course) => course.id).includes(+id)) setIsBought(true);
  }, [boughtCourses, id]);

  // const getDate = (inputDate) => {
  //   const date = new Date(inputDate);
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const year = date.getFullYear();
  //
  //   return `${day}.${month}.${year}`;
  // };

  const getDate = (date) => {
    const [year, month, day] = date.split('T')[0].split('-');
    return `${day}.${month}.${year}`;
  }
  const getDuration = (days) => {
    if (days < 30) {
      return days + ' ' + t('frequentlyUsedExpressions.days');
    }
    return Math.round(days / 30) + ' ' + t('frequentlyUsedExpressions.months') + ((Math.round(days / 30) > 1 && i18n.language === 'en') ? 's' : '');
  };

  const handleGetStarted = () => {
    if (user && !isBought) {
      document.getElementById('payment')?.scrollIntoView({ behavior: 'smooth' });
    } else if (isBought) {
      navigate('/' + user.role.toLowerCase());
    } else {
      navigate('/registration');
    }
  };

  return (
      <>
        <Helmet>
          <title>{course?.title ? `${course.title} | Tesvan Platform` : "| Tesvan Platform"}</title>
          <meta name="description" content={course?.shortDescription || "Explore courses on Tesvan Platform"} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        {isLoading || (user && user.role === ROLES.STUDENT && !boughtCourses) ? (
            <Loader />
        ) : (
            <div>
              <Header
                  short
                  children
                  small={''}
                  big={course?.title || ''}
                  img={'/images/coursesGirl.webp'}
                  imgClass={styles.image}
                  text={course?.shortDescription || ''}
                  buttonCont={
                    <>
                      <Button text={isBought ? t('dashboardLayout.goToDashboard') : t('header.getStarted')} className={'bigPrimary'} handleButtonClick={handleGetStarted} />
                      {!isBought && <Button text={t('header.watchHowItWorks')} className={'bigSecondary'}  handleButtonClick={() => navigate({pathname: '/courses', hash: 'howItWorks'})} /> }
                    </>
                  }
              >
                <ul className={styles.info}>
                  <li>
                    <div className={styles.iconHolder}>
                      <img src="/images/sandWatch.png" alt="Duration" className={styles.imgs} width={40} />
                    </div>
                    <span>{t('courseSinglePage.duration')}</span>
                    <p>{course?.duration}</p>
                  </li>
                  <li>
                    <div className={styles.iconHolder}>
                      <img src="/images/singleStart.png" alt="Duration" className={styles.imgs}  width={40} />
                    </div>
                    <span>{t('courseSinglePage.starts')}</span>
                    <p>{course?.startDate}</p>
                  </li>
                  <li>
                    <div className={styles.iconHolder}>
                      <img src="/images/lessons.png" alt="Duration" className={styles.imgs}  width={40} />
                    </div>
                    <span>{t('frequentlyUsedExpressions.lessons')}</span>
                    <p>{course?.Lessons?.length} {t('frequentlyUsedExpressions.lessons').toLowerCase()}</p>
                  </li>
                  <li>
                    <div className={styles.iconHolder}>
                      <img src="/images/coursetype.png" alt="Duration" className={styles.imgs}  width={40} />
                    </div>
                    <span>{t('courseSinglePage.type')}</span>
                    <p>{t(`frequentlyUsedExpressions.${course?.courseType.toLowerCase()}`)}</p>
                  </li>
                </ul>
              </Header>
              <div className={styles.description}>
                <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>
                  {t('courseSinglePage.aboutOurCourse')}
                  {/*<TextHighlighter originalString={`${t('courseSinglePage.whatIs')} ${course?.title}${i18n.language === 'en' ? '?' : 'ը'}`} startIndex={6} endIndex={13} />*/}
                </p>
                <p className={styles.courseDescription}>{course?.description}</p>
              </div>
              <PaymentMethods payment={course?.payment} courseType={course?.courseType} />
              <CourseProgram lessons={course?.Lessons} />
              <WhyThisCourse content={course?.whyThisCourse} />
              <ForWhomIsCourse levels={course?.levelDescriptions} />
              <Trainers cont={course?.trainers} />
              <CourseCertificates />
              <StudentsSay />
            </div>
        )}
      </>
  );
};

export default SingleCourse;
