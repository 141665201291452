import React, {Fragment, useEffect, useState} from 'react';
import styles from "./Quiz.module.css";
import Timer from "../../../Components/Timer/Timer";
import QuizList from "../QuizList/QuizList";
import axios from "../../../axios/axios";
import {Link, useNavigate, useParams} from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import { useTranslation } from "react-i18next";

const Quiz = () => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [data, setData] = useState({});
    const [dataPoint, setDataPoint] = useState({});
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [quizTime, setQuizTime] = useState(0);
    const { lessonId, idcourses, quizIdStudnets } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/quizz/getQuizzes/${quizIdStudnets}`, { params: { courseId: idcourses } });
                setData(response.data.quizz);
                setQuizTime(response.data.quizz.time * 60);
            } catch (error) {
                if (error.response.data.message === 'already passed') {
                    navigate('?isPassed=true');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [quizIdStudnets]);

    useEffect(() => {

        setStartTime(new Date().getTime());

        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = 'Are you sure you want to leave this page? Your quiz will be submitted.';
            return 'Are you sure you want to leave this page? Your quiz will be submitted.';
        };

        const handleNavigation = (event) => {
            const confirmationMessage = 'Are you sure you want to leave this page? Your quiz will be submitted.';
            if (window.confirm(confirmationMessage)) {
                event.preventDefault();
                finishQuiz().then(() => navigate(-1))
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handleNavigation);

         navigate((location, action) => {
            if (action === 'PUSH' || action === 'POP') {
                const confirmationMessage = 'Are you sure you want to leave this page? Your quiz will be submitted.';
                if (window.confirm(confirmationMessage)) {
                    navigate(-1);
                }
            }
        });

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handleNavigation);
        };
    }, [navigate]);


    const handleAnswerButtonClick = (selectedOption) => {
        const updatedSelectedAnswers = [...selectedAnswers];
        updatedSelectedAnswers[currentQuestion] = selectedOption;
        setSelectedAnswers(updatedSelectedAnswers);
    };

    const handleNextButtonClick = async () => {
        const questionId = data?.Questions[currentQuestion]?.id;
        const optionId = data?.Questions[currentQuestion]?.Options?.find(option => option?.title === selectedAnswers[currentQuestion])?.id;

        if (optionId && questionId) {
            await submitAnswerToServer(questionId, optionId);

            const nextQuestion = currentQuestion + 1;
            if (nextQuestion < data.Questions.length) {
                setCurrentQuestion(nextQuestion);
            } else {
                setShowScore(true);
                setEndTime(new Date().getTime());
                await finishQuiz();
            }
        }
    };

    const submitAnswerToServer = async (questionId, optionId) => {
        if (questionId && optionId) {
            try {
                await axios.post(
                    `quizz/submitQuizz?courseId=${idcourses}&lessonId=${lessonId}`,
                    { quizzId: parseInt(quizIdStudnets), questionId, optionId }
                );
            } catch (error) {
                console.log(error);
            }
        }
    };

    const finishQuiz = async () => {
        try {
            await axios.post(
                `quizz/finishQuizz?courseId=${idcourses}&lessonId=${lessonId}`,
                {
                    quizzId: parseInt(quizIdStudnets),
                    isFinal: !lessonId,
                    lessonId: lessonId ? parseInt(lessonId) : null
                }
            );

            const userAnswersResponse = await axios.post(`/quizz/getUserAnswers?courseId=${idcourses}&lessonId=${lessonId}`, {
                quizzId: parseInt(quizIdStudnets),
                courseId: parseInt(idcourses),
                isFinal: !lessonId
            });
            console.log('User answers:', userAnswersResponse.data);
            setDataPoint(userAnswersResponse.data);
        } catch (error) {
            console.error(error);
        }
    };

    const calculateTime = () => {
        const totalTimeInSeconds = (endTime - startTime) / 1000;
        const minutes = Math.floor(totalTimeInSeconds / 60);
        const seconds = Math.floor(totalTimeInSeconds % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${minutes === 0 ? t('userQuiz.sec') : t('userQuiz.min')}`;
    };

    return (
        <div>
            {loading ? (
                <Loader />
            ) : showScore && dataPoint ? (
                <div className={styles.score}>
                    <p className={styles.scoretext}>
                        {dataPoint?.correctAnswers === data?.Questions.length
                            ? (i18n.language === 'en' ? `Perfect score! You aced the quiz with ${dataPoint.correctAnswers} out of ${data?.Questions.length} correct answers` : ` Հիանալի արդյունք է։ ${data?.Questions.length} հարցից ճիշտ ես պատասխանել ${dataPoint.correctAnswers}-ին։`)
                            : (i18n.language === 'en' ? `You scored ${dataPoint?.correctAnswers} out of ${data?.Questions.length} correct answers.` : `${data?.Questions.length} հարցից ճիշտ ես պատասխանել ${dataPoint?.correctAnswers}-ին։`)}
                    </p>
                    <p className={styles.timeredit}>{calculateTime()}</p>
                    <p className={styles.great}>{dataPoint?.correctAnswers === data?.Questions.length ? t('userQuiz.great') : t('userQuiz.couldPassBetter')}</p>
                    <div className={styles.buttonContainer}>
                        <Link
                            className={styles.que}
                            to={'..'}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('?isPassed=true');
                            }}
                        >
                            {t('userQuiz.seeResult')}
                        </Link>
                        <Link
                            className={styles.que}
                            to={'..'}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(-1);
                            }}
                        >
                            {t('userQuiz.backToLesson')}
                        </Link>
                    </div>
                </div>
            ) : (
                <div className={styles.only}>
                    <div className={styles.courscont}>
                        <div className={styles.titleDiv}>
                            <p className={styles.currentIndex}>{currentQuestion + 1}</p>
                            <p>{data?.Questions[currentQuestion].title}</p>
                        </div>
                        <div className={styles.ansver}>
                            {data?.Questions[currentQuestion].Options.map((option, index) => (
                                <button
                                    className={`${styles.btn} ${selectedAnswers[currentQuestion] === option.title ? styles.selected : ''}`}
                                    onClick={() => handleAnswerButtonClick(option.title)}
                                    key={option.id}
                                >
                                    <p className={styles.indexbut}>{index + 1}. </p>
                                    <p className={styles.textin}>
                                        {option.title.split('\n').map((line, idx) => (
                                        <Fragment key={idx}>
                                            {line}
                                            {idx !== option.title.split('\n').length - 1 && <br />}
                                        </Fragment>
                                        ))}
                                    </p>
                                </button>
                            ))}
                        </div>
                        <div className={styles.divquest}>
                            <button onClick={() => setCurrentQuestion(currentQuestion - 1)} className={styles.que} disabled={currentQuestion === 0}>
                                {t('userQuiz.back')}
                            </button>
                            <button onClick={handleNextButtonClick} className={styles.que}>
                                {currentQuestion === data?.Questions.length - 1 ? t('userQuiz.finish') : t('userQuiz.next')}
                            </button>
                        </div>
                    </div>
                    <div className={styles.leftdiv}>
                        <Timer initialTime={quizTime} />
                        <QuizList currentQuestionIndex={currentQuestion} questions={data?.Questions} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Quiz;
