import React from 'react';
import styles from "./AdminCourses.module.css"
import Button from "../../../Components/./Button/button";
import TableAdminCourses from "./TableAdminCourses/TableAdminCourses";
import {Link} from "react-router-dom";
import {ROLES, useUserContext} from "../../../Components/UserContext/UserContext";
function AdminCourses(props) {

    const {user} = useUserContext();

    return (
        <div>
            <div className={styles.cont}>
                <p className={styles.title}>Courses</p>
                {user?.role === ROLES.TEACHER && <Link to={'newcourse'} className={styles.link} >+ Create new course</Link>}
            </div>
            <div >
                <TableAdminCourses />
            </div>
        </div>
    );
}

export default AdminCourses;