import React, { useEffect, useRef, useState } from "react";
import styles from "./cookie.module.css";
import { RiArrowDownSLine } from "react-icons/ri";
import Button from "../../Components/./Button/button";
import Toggle from "../../Components/Switch/Switch";

function ManageCookie({ setShowModal, setShow }) {
    const modalRef = useRef(null);
    const [info, setInfo] = useState([
        {
            title: "Essential cookies",
            text: "These cookies enable basic functions like page navigation and access to secure areas of the Website.",
            isOpen: true,
        },
        {
            title: "Analytical cookies",
            text: "These cookies allow us to analyze how you use the Website, so we can measure and improve the performance of our Website.",
            isOpen: false,
        },
        {
            title: "Functionality cookies",
            text: "These cookies enable the Website to provide enhanced functionality and personalization.",
            isOpen: false,
        },
        {
            title: "Social Media cookies",
            text: "These cookies are used to make advertising messages more relevant to you.",
            isOpen: false,
        }
    ]);

    const toggleOpen = (index) => {
        const updatedInfo = info.map((item, i) => ({
            ...item,
            isOpen: i === index ? !item.isOpen : false,
        }));
        setInfo(updatedInfo);
    };

    useEffect(() => {
        const handleClick = (e) => {
            if (!modalRef?.current?.contains(e.target)) {
                setShowModal(false);
                setShow(true);
            }
        };

        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [setShowModal, setShow]);

    return (
        <div className={styles.manage} id={"manageCookie"} ref={modalRef}>
            <img
                src="/images/close.png"
                className={styles.close}
                onClick={() => {
                    setShowModal(false);
                    setShow(true);
                }}
                alt={"close"}
            />
            <p className={styles.title}>
                Manage your cookies
            </p>
            <p className={styles.info + " " + styles.bottom}>
                You're welcome to customize your cookie settings as you like.
                Please note that essential cookies are always on.
            </p>
            <div className={styles.container}>
                {info.map((item, index) => (
                    <div key={index} className={styles.infocont}>
                        <div className={styles.con}>
                            <div className={styles.itemcont}>
                                <RiArrowDownSLine
                                    className={
                                        item.isOpen ? styles.arrowactive : styles.arrow
                                    }
                                    onClick={() => toggleOpen(index)}
                                />
                                <p className={styles.item}>{item.title}</p>
                            </div>
                            {index === 0 ? (
                                <p className={styles.info}>Always on</p>
                            ) : (
                                <Toggle />
                            )}
                        </div>
                        {item.isOpen && (
                            <p
                                className={styles.info}
                                style={{ color: "#C2C7CB" }}
                            >
                                {item.text}
                            </p>
                        )}
                    </div>
                ))}
            </div>
            <div className={styles.button}>
                <Button
                    className={"secondary"}
                    text={"Save preferences"}
                    handleButtonClick={() => setShowModal(false)}
                />
                <Button
                    className={"primary"}
                    text={"Accept all"}
                    handleButtonClick={() => {
                        localStorage.setItem("cookies", "1");
                        setShowModal(false);
                        setShow(false);
                    }}
                />
            </div>
        </div>
    );
}

export default ManageCookie;
