import React, {useEffect, useState} from "react";
import styles from './course.module.css'
import { useNavigate } from "react-router-dom";
import axios from '../../axios/axios'
import {useUserContext} from "../UserContext/UserContext";
import {useTranslation} from "react-i18next";

function Course({
    id,
    img,
    bought,
    sale,
    courseType,
    lessonType,
    level,
    price,
    title,
    description,
    courseDate,
    courseDateStart,
    comingSoon,
    inProgress
}) {
    const {user, setUser} = useUserContext()
    const [isLiked, setIsLiked] = useState(false);
    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => {
       setIsLiked(user?.likedCourses?.includes(id))
    }, [user, id])
    const navigateToCourse = () => {
        navigate('/courses/' + id)
    }

    const handleLikeClick = e => {
        e.stopPropagation()
            if(user) {
                if(isLiked) {
                    setUser(user => ({...user, likedCourses: user.likedCourses.filter((course) => course !==id)}))
                } else {
                    setUser(user => ({...user, likedCourses: [...user.likedCourses, id]}))
                }
            }
        axios.get(`/courses/like/${id}`).then(() => {
            setIsLiked(!isLiked)
        }).catch(error => {
            if(error.response.status === 401) navigate('/login')
        })
    };

    return (
        <>
            <div className={`${styles.container} ${comingSoon ? styles.pointerEvents : ''}`} onClick={() => navigateToCourse()}>
                <div className={`${styles.image} ${comingSoon ? styles.disabled : ''}`} style={{background: `url(${img})`}} />
                {inProgress &&
                    <>
                        <img src='/images/disabled.png' className={styles.comingSoon} alt={'disabled'}/>
                        <p className={styles.soon}>In progress</p>
                    </>
                }
                {comingSoon ?
                    <>
                        <img src='/images/disabled.png' className={styles.comingSoon} alt={'disabled'}/>
                        <p className={styles.soon}>{t('homepage.chooseYourLearningType.comingSoon')}</p>
                        <div className={`${styles.comingSoonContainer} ${ comingSoon ?  styles.disabled : ''}`}>
                            <p className={styles.title}>{title}</p>
                            <p className={styles.format}>Level Up</p>
                        </div>
                    </>
                    :
                    <>
                        <div className={styles.like}>
                            <div className={styles.num}>
                                <img src='/images/number.png' className={styles.icon} alt={'number'} />
                                <p className={styles.number}>{bought}</p>
                            </div>
                            <img
                                src={isLiked ? '/images/heartActive.png' : '/images/heart.png'}
                                className={styles.heart}
                                onClick={handleLikeClick}
                                alt={'liked'}
                            />
                        </div>
                        <div className={styles.cont}>
                            <div className={styles.divcont}>
                                <img src='/images/groupSpan.png' className={styles.icon} alt={'group'}/>
                                <p className={styles.innertext}>{courseType}</p>
                            </div>
                            <div className={styles.divcont}>
                                <img src='/images/calendar.png' className={styles.icon} alt={'calendar'}/>
                                <p className={styles.innertext}>{courseDate}</p>
                            </div>
                            <div className={styles.divcont}>
                                <img src='/images/level.png' className={styles.icon} alt={'level'} />
                                <p className={styles.innertext}>{level}</p>
                            </div>
                        </div>
                        <div className={styles.hcont}>
                            <p className={styles.header}>{title}</p>
                            <p className={styles.online}>{lessonType}</p>
                        </div>
                        <p className={styles.text}>{description}</p>
                        <div className={styles.costcont}>
                            <p className={styles.date}>{courseDateStart}</p>
                            {/*{sale !== price && (*/}
                            {/*    <p className={styles.sale}>{price} ֏</p>*/}
                            {/*)}*/}
                            <div>
                                <p className={styles.cost}>
                                    {sale !== price ? `${sale} ֏` : `${price} ֏`}
                                </p>
                                <p className={styles.month}>{t('frequentlyUsedExpressions.monthly')}</p>
                            </div>
                        </div>
                    </>
                }
                </div>
        </>
    );
}

export default Course;