import React from "react";
import styles from '../../Home/LearnFrom/learnfrom.module.css'
import Button from "../../../Components/./Button/button";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function ContactUsComp({margin}) {

    const navigate = useNavigate()
    const {t} = useTranslation()

    return(
        <div className={styles.container}
             style={margin ? {gap: window.innerWidth > 430 ?'40px' : '10px',
                     marginTop: window.innerWidth < 1430 ? '720px' : '550px',
                 } : {gap: window.innerWidth > 430 ?'40px' : '10px', marginTop: '50px'}}
        >
            <p className={styles.head}>
                {t('aboutUs.missionAndValues.haveQuestions')}
            </p>
            <p className={styles.text}>
                {t('aboutUs.missionAndValues.haveQuestionsDescriptionFirst')}{' '}
                <Link className={styles.link} to={'/company/FAQ'}>{t('aboutUs.missionAndValues.FAQ')}</Link>{' '}
                {t('aboutUs.missionAndValues.haveQuestionsDescriptionSecond')}
            </p>
            <img src='/images/armchairGirl.png' className={styles.img} />
            <Button text={t('navigation.contactUs')} className={'bigPrimary'} handleButtonClick={() => navigate('/company/contactUs')}/>
        </div>
    )
}

export default ContactUsComp;