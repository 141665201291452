import React, { useState } from "react";
import styles from './contactus.module.css'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import RegPage from "../Registration/RegPage/RegPage";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Link } from "react-router-dom";
import Textarea from "../../Components/Textarea/Textarea";
import Checkbox from "../../Components/Checkbox/Checkbox";
import Button from "../../Components/Button/button";
import axios from "../../axios/axios";
import { useTranslation } from "react-i18next";
import { FaRegEnvelope } from "react-icons/fa";
import {Helmet} from "react-helmet-async";

function ContactUs() {
    const { t, i18n } = useTranslation();

    const [phone, setPhone] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [agree, setAgree] = useState(false);
    const [phoneErr, setPhoneErr] = useState(null);
    const [nameErr, setNameErr] = useState(null);
    const [lastNameErr, setLastNameErr] = useState(null);
    const [emailErr, setEmailErr] = useState(null);
    const [sendMessage, setSendMessage] = useState({ message: '', error: false });

    const regex = /^[\p{L}\s\-']+$/u;
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    const validatePhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return null;
        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        return parsedNumber ? parsedNumber.isValid() : false;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!nameErr && !lastNameErr && !emailErr) {
            axios.post('/contactMessage/create', {
                firstName,
                lastName,
                email,
                message,
                phone
            }).then(() => {
                setSendMessage({ message: t('contactUs.successMessage'), error: false });
                setPhone('');
                setEmail('');
                setFirstName('');
                setLastName('');
                setMessage('');
                setAgree(false);
                setLastNameErr(null);
                setEmailErr(null);
                setNameErr(null);
                setPhoneErr(null);
            })
                .catch(() => {
                    setSendMessage({ message: t('contactUs.errorMessage'), error: true });
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Contact Us - We're Here to Help</title>
                <meta name="description" content="Get in touch with us for any inquiries or support. We're here to help you with your questions." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <RegPage
                without
                img={"/images/contactGirl.png"}
                className={styles.image}
                cont={
                    <>
                        <p className={styles.quest}>{t('contactUs.getInTouch')}</p>
                        <a href='tel:+37499790270' className={styles.link}>
                            <img src={'/images/telephone.png'} className={styles.img} alt={'phone'} />
                            &nbsp; +(374) 99 79 02 70
                        </a>
                        <a href='mailto:sayhello@tesvan.com' className={styles.link}>
                            <FaRegEnvelope className={styles.imgs} />
                            &nbsp; sayhello@tesvan.com
                        </a>
                        <p className={styles.link + ' ' + styles.notHover}>
                            <img src={'/images/place.png'} alt={'place'} className={styles.img} />
                            &nbsp; {t('footer.Armenia')}
                        </p>
                        <div className={styles.conticon}>
                            <a href='https://www.facebook.com/tesvanllc' target={'_blank'} rel="noopener noreferrer">
                                <img src='/images/facebookIcon.png' className={styles.icon} alt={'facebook'} />
                            </a>
                            <a href='https://www.linkedin.com/company/tesvan/mycompany' target={'_blank'} rel="noopener noreferrer">
                                <img src='/images/LnIcon.png' className={styles.icon} alt={'Ln'} />
                            </a>
                            <a href='https://www.youtube.com/@tesvan202' target={'_blank'} rel="noopener noreferrer">
                                <img src='/images/youtubeIcon.png' className={styles.icon} alt={'youtube'} />
                            </a>
                            <a href='https://www.instagram.com/tesvan_official' target={'_blank'} rel="noopener noreferrer">
                                <img src='/images/instagramIcon.png' className={styles.icon} alt={'Instagram'} />
                            </a>
                            <a href='https://t.me/tesvan_official' target={'_blank'} rel="noopener noreferrer">
                                <img src='/images/telegramIcon.png' className={styles.icon} alt={'Telegram'} />
                            </a>
                        </div>
                    </>
                }
                content={
                    <div className={styles.content}>
                        <form className={styles.content} onSubmit={onSubmit}>
                            <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>
                                {t('contactUs.title')}
                            </p>
                            <p className={styles.text}>
                                {t('contactUs.reachOut')} <Link to={'/company/FAQ'} className={styles.lin}>{t('navigation.FAQ')}</Link> {t('contactUs.reachOutSequence')}
                            </p>
                            <div className={styles.container}>
                                <div className={styles.inputcont}>
                                    <label className={styles.label}>
                                        <span className={styles.caption}>{t('registration.firstStep.firstName')}</span>
                                        <input
                                            type="text"
                                            className={`${styles.input} ${nameErr === null ? "" : nameErr ? styles.errorinp : styles.noterrinp}`}
                                            value={firstName}
                                            placeholder={t('registration.firstStep.firstNamePlaceholder')}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            onBlur={() => {
                                                setNameErr(!regex.test(firstName));
                                            }}
                                        />
                                    </label>
                                    {nameErr && <p className={styles.error}>{t('registration.firstStep.firstNameError')}</p>}
                                </div>
                                <div className={styles.inputcont}>
                                    <label className={styles.label}>
                                        <span className={styles.caption}>{t('registration.firstStep.lastName')}</span>
                                        <input
                                            type="text"
                                            className={`${styles.input} ${lastNameErr === null ? "" : lastNameErr ? styles.errorinp : styles.noterrinp}`}
                                            value={lastName}
                                            placeholder={t('registration.firstStep.lastNamePlaceholder')}
                                            onChange={(e) => setLastName(e.target.value)}
                                            onBlur={() => {
                                                setLastNameErr(!regex.test(lastName));
                                            }}
                                        />
                                    </label>
                                    {lastNameErr && <p className={styles.error}>{t('registration.firstStep.lastNameError')}</p>}
                                </div>
                                <div className={styles.inputcont}>
                                    <label className={styles.label}>
                                        <span className={styles.caption}>{t('registration.firstStep.email')}</span>
                                        <input
                                            type="text"
                                            className={`${styles.input} ${emailErr === null ? "" : emailErr ? styles.errorinp : styles.noterrinp}`}
                                            value={email}
                                            placeholder="johnsmith@example.com"
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={() => {
                                                setEmailErr(!emailRegex.test(email));
                                            }}
                                        />
                                    </label>
                                    {emailErr && <p className={styles.error}>{t('registration.firstStep.emailError')}</p>}
                                </div>
                                <div className={styles.inputcont}>
                                    <label className={styles.label}>
                                        <span className={styles.caption}>{t('registration.firstStep.phone')} ({t('frequentlyUsedExpressions.optional')})</span>
                                        <PhoneInput
                                            defaultCountry="am"
                                            value={phone}
                                            name="phoneInput"
                                            className={`${styles.phone} ${phoneErr !== null && phoneErr ? styles.phoneerr : ''}`}
                                            onChange={(phone) => setPhone(phone)}
                                            onBlur={() => {
                                                setPhoneErr(phone ? !validatePhoneNumber(phone) : null);
                                            }}
                                        />
                                    </label>
                                    {phoneErr && <p className={styles.error}>{t('registration.firstStep.phoneError')}</p>}
                                </div>
                                <label className={styles.label}>
                                    <span className={styles.caption}>{t('contactUs.message')}</span>
                                    <Textarea
                                        placeholder={t('contactUs.typeYourMessage')}
                                        value={message}
                                        onChange={e => setMessage(e.target.value)}
                                        max={1000}
                                    />
                                </label>
                            </div>
                            <div className={styles.check}>
                                <Checkbox
                                    value={agree}
                                    handleCheckboxChange={(e) => setAgree(e.target.checked)}
                                />
                                <span className={styles.text}>
                                {t('registration.thirdStep.agreeTerms')} &nbsp;
                                    <Link className={styles.lin} to={'/termsOfUse'}>{t('registration.thirdStep.termsOfUse')}</Link> {t('frequentlyUsedExpressions.and')} &nbsp;
                                    <Link className={styles.lin} to={'/privacyPolicy'}>{t('registration.thirdStep.privacyPolicy')}</Link>.
                            </span>
                            </div>
                            {sendMessage.message &&
                                <p className={`${styles.message} ${sendMessage.error ? styles.error : styles.success}`}>
                                    <img src={sendMessage.error ? '/images/errorMessage.png' : '/images/successMessage.png'} className={styles.messageImg} alt="status" />
                                    &nbsp; {sendMessage.message}
                                </p>
                            }
                            <Button type={'submit'} text={t('contactUs.sendMessage')} className={'primary'} disabled={!agree || !firstName || !lastName || !email || emailErr || phoneErr || !message} />
                        </form>
                    </div>
                }
            />
        </>
    );
}

export default ContactUs;
