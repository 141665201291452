import React, {useState, useEffect, useRef} from 'react';
import {Navigate, Route, Routes, useLocation, useSearchParams} from 'react-router-dom';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'video-react/dist/video-react.css';
import Layout from './Pages/Layout/Layout';
import HomePage from './Pages/Home/HomePage';
import Registration from './Pages/Registration/Registration';
import Verification from './Pages/Registration/Verification';
import Verify from './Pages/Registration/Verification/Verify';
import Login from './Pages/Login/Login';
import ChangeEmail from './Pages/Registration/Verification/ChangeEmail/ChangeEmail';
import ForgotPassword from './Pages/Login/ForgotPassword/ForgotPassword';
import ChangePassword from './Pages/Login/ChangePassword/ChangePassword';
import Courses from './Pages/Courses/Courses';
import Cookies from './Pages/Cookies/Cookies';
import TermsOfUse from './Pages/TermsOfUse/TermsOfUse';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import FAQ from './Pages/FAQ/FAQ';
import SingleCourse from './Pages/SingleCourse/SingleCourse';
import ContactUs from './Pages/ContactUs/ContactUs';
import About from './Pages/About/About';
import axios from '../src/axios/axios';
import { ROLES, useUserContext } from './Components/UserContext/UserContext';
import ErrorPage from './Pages/ErrorPage/ErrorPage';
import DashLayout from './Dashboard/DashLayout/DashLayout';
import Home from './Dashboard/Home/Home';
import Homework from './Dashboard/Homework/Homework';
import SingleHomework from './Dashboard/SingleHomework/SingleHomework';
import HomeCourses from './Dashboard/HomeCourses/HomeCourses';
import CoursLessons from './Dashboard/HomeCourses/CoursLessons/CoursLessons';
import LessonsProgress from './Dashboard/HomeCourses/CoursLessons/LessonsProgress/LessonsProgress';
import Chat from './Dashboard/Chat/Chat';
import Certification from './Dashboard/Certification/Certification';
import BigCalendar from './Dashboard/Calendar/Calendar';
import moment from 'moment';
import QuizSections from './Dashboard/HomeCourses/QuizSections/QuizSections';
import VideoCourse from './Dashboard/HomeCourses/VideoCourse/VideoCourse';
import Settings from './Dashboard/Settings/Settings';
import Account from './Dashboard/Settings/Account/Account';
import Knowledge from './Dashboard/Settings/Knowledge/Knowledge';
import PasswordUpdate from './Dashboard/Settings/PasswordUpdate/PasswordUpdate';
import DeleteAccount from './Dashboard/Settings/DeleteAccount/DeleteAccount';
import Teacher from './TeacherDashboard/Teacher/Teacher';
import RolesLayout from './Components/RolesLayout/RolesLayout';
import StudentQuizResults from './TeacherDashboard/Teacher/StudentQuizResults/StudentQuizResults';
import QuizAnswers from './TeacherDashboard/Teacher/QuizzAnswers/QuizAnswers';
// import Permissions from './Dashboard/Settings/Permissions/Permissions';
import MyGroup from './TeacherDashboard/Teacher/MyGroup/MyGroup';
import GroupCourse from './TeacherDashboard/Teacher/MyGroup/GroupCourse/GroupCourse';
import StudentSide from './TeacherDashboard/Teacher/MyGroup/GroupCourse/StudentSide/StudentSide';
import TeachersSide from './TeacherDashboard/Teacher/MyGroup/GroupCourse/TeachersSide/TeachersSide';
import StudentInfo from './TeacherDashboard/Teacher/MyGroup/GroupCourse/StudentSide/StudentInfo/StudentInfo';
import CertificationTeacher from './TeacherDashboard/Certification/CertificationTeacher';
import TeacherSingleHomework from './TeacherDashboard/Homework/TeacherSingleHomework/TeacherSingleHomework';
import UserHomework from './TeacherDashboard/Homework/TeacherSingleHomework/UserHomework/UserHomework';
import AdminDashboard from './Admin/AdminDashboard/AdminDashboard';
import AdminCourses from './Admin/AdminDashboard/AdminCourses/AdminCourses';
import CreateNewCourseAdmin from './Admin/AdminDashboard/AdminCourses/CreateNewCourseAdmin/CreateNewCourseAdmin';
import CourseID from './Admin/AdminDashboard/AdminCourses/CourseID/CourseID';
import AdminCourseLessons from './Admin/AdminDashboard/AdminCourses/CourseID/AdminCourseLessons/AdminCourseLessons';
// import AdminCourseParticipant from './Admin/AdminDashboard/AdminCourses/CourseID/AdminCourseParticipant/AdminCourseParticipant';
import AdminCourseSettings from './Admin/AdminDashboard/AdminCourses/CourseID/AdminCourseSettings/AdminCourseSettings';
import AdminCreateNewLessons from './Admin/AdminDashboard/AdminCourses/CourseID/AdminCourseLessons/AdminCreateNewLessons/AdminCreateNewLessons';
import AdminAddQuiz from './Admin/AdminDashboard/AdminCourses/CourseID/AdminCourseLessons/AdminCreateNewLessons/AdminAddQuiz/AdminAddQuiz';
import AdminMyGroup from './Admin/AdminDashboard/AdminMyGroup/AdminMyGroup';
import AdminGroupMembers from './Admin/AdminDashboard/AdminMyGroup/GroupID/AdminGroupMembers/AdminGroupMembers';
import AdminGroupAssessment from './Admin/AdminDashboard/AdminMyGroup/GroupID/AdminGroupAssessment/AdminGroupAssessment';
// import AdminGroupSettings from './Admin/AdminDashboard/AdminMyGroup/GroupID/AdminGroupSettings/AdminGroupSettings';
import GroupID from './Admin/AdminDashboard/AdminMyGroup/GroupID/GroupID';
import AdminUsers from './Admin/AdminDashboard/AdminUsers/AdminUsers';
import StudentAdminInfo from './Admin/AdminDashboard/AdminUsers/StudentAdminInfo/StudentAdminInfo';
import TeacherAdminInfo from './Admin/AdminDashboard/AdminUsers/TeacherAdminInfo/TeacherAdminInfo';
import Platform from './Pages/Platform/Platform';
import CourseLayout from './Components/CourseLayout/CourseLayout';
import BoughtCoursesLoader from "./Components/BoughtCoursesLoader/BoughtCoursesLoader";
import {useUserCourseContext} from "./Components/UserCourseContext/UserCourseContext";
import NoCourseBought from "./Dashboard/Home/NoCourseBought";
import OverallProgressTests from './Dashboard/Tests/OveralProgressTests/OverallProgressTests'
import StudentTest from "./Dashboard/Tests/StudentTest/StudentTest";
import Payment from "./Pages/Payment/Payment";
import AdminTest from "./Admin/AdminDashboard/AdminTest/AdminTest";
import AdminCreateATest from "./Admin/AdminDashboard/AdminTest/TestsListInfo/AdminCreateATest/AdminCreateATest";
import AdminCreateNewQuiz from "./Admin/AdminDashboard/AdminCreateNewQuiz/AdminCreateNewQuiz";
import AdminCreateLessons from "./Admin/AdminDashboard/AdminCreateNewLessons/AdminCreateLessons";
import AdminWebsite from "./Admin/AdminDashboard/AdminWebsite/AdminWebsite";
import Testimonials from "./Admin/AdminDashboard/AdminWebsite/Testimonials/Testimonials";
import FAQs from "./Admin/AdminDashboard/AdminWebsite/FAQs/FAQs";
import Contacts from "./Admin/AdminDashboard/AdminWebsite/Contacts/Contacts";
import Careers from "./Admin/AdminDashboard/AdminWebsite/Careers/Careers";
import BlogAdmin from "./Admin/AdminDashboard/AdminWebsite/BlogAdmin/BlogAdmin";
import EditLessons
  from "./Admin/AdminDashboard/AdminCourses/CourseID/AdminCourseLessons/AdminCreateNewLessons/EditLessons";
import EditQuizz
  from "./Admin/AdminDashboard/AdminCourses/CourseID/AdminCourseLessons/AdminCreateNewLessons/AdminAddQuiz/EditQuizz";
import EditTest from "./Admin/AdminDashboard/AdminTest/TestsListInfo/AdminCreateATest/EditTest";
import CreateGroup from "./Admin/AdminDashboard/AdminMyGroup/CreateGroup/CreateGroup";
import EditeGroupNew from "./Admin/AdminDashboard/AdminMyGroup/EditeGroupNew/EditeGroupNew";
import PaymentSuccess from "./Pages/Payment/PaymentMessage/PaymentSuccess";
import PaymentFail from "./Pages/Payment/PaymentMessage/PaymentFail";
import UserSettingsVerificate from "./Dashboard/Settings/Account/UserSettingsVerificate/UserSettingsVerificate";
import {useTranslation} from "react-i18next";
import HomeworkPerGroup from "./TeacherDashboard/Homework/HomeworkPerGroup/HomeworkPerGroup";
import {io} from "socket.io-client";
import PaymentTable from "./Dashboard/Payment/PaymentTable";
import StudentsTable from "./TeacherDashboard/StudentsTable/StudentsTable";
import AdminPayment from "./Admin/Payment/PaymentTable/AdminPayment";
import PaymentGroups from "./Admin/Payment/PaymentGroups/PaymentGroups";
import AdminCreateHomework from "./Admin/AdminCreateHomework/AdminCreateHomework";
import HomeworkEdit from "./Admin/HomeworkEdit/HomeworkEdit";
import Subscrictions from './Admin/Subscriptions/Subscriptions';

function App() {
  const location = useLocation();
  const [socket, setSocket] = useState(null);
  const [hasNewMessages, setHasNewMessages] = useState(false);
  const [newMessages, setNewMessages] = useState(null);
  const {boughtCourses} = useUserCourseContext();
  const isCourseBought = boughtCourses?.length > 0;
  const newMessageSoundRef = useRef(new Audio('/sounds/archivo.mp3'));
  const [connectedUser, setConnectedUser ] = useState({id: null, online: false});
  const {user, setUser, setStatus} = useUserContext();

  useEffect(() => {
    const token = localStorage.getItem("authToken") || sessionStorage.getItem("authToken");
    if (user) {
      const newSocket = io("https://platform.tesvan.com", { query: { token: token } });
      setSocket(newSocket);

      return () => newSocket.close();
    }
  }, [user]);

  useEffect(() => {
    if(user && socket) {
      socket?.on('online', (user) => {
        setConnectedUser({id: user.userId, online: true})
      });

      socket?.on('offline', (user) => {
        setConnectedUser({id: user.userId, online: false})
      });

      socket?.emit( 'join', {userId: user?.id, groupChats: user?.groupChats.map(chat => chat.id)})

      return () => {
        socket?.off('online')
        socket?.off('offline')
      };
    }
  }, [user, socket]);

  useEffect(() => {
    if((isCourseBought || user?.role !== ROLES.STUDENT) && user) {
      socket?.on("new-message", () => {
        setHasNewMessages(true);
        newMessageSoundRef.current.play().catch(error => console.log(error));
      });

      axios.get("/message/getNewMessages").then(({ data }) => {
        setHasNewMessages(data?.length > 0);
      });

      return () => {
        socket?.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if((isCourseBought || user?.role !== ROLES.STUDENT) && user) {
      axios.get('/chatMessage/getMessageNotifications')
          .then(({data}) => {
            setNewMessages(data)
          })
    }
  }, [isCourseBought, user])

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission === "default") {
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            console.log("Notification permission granted.");
          } else {
            console.log("Notification permission denied.");
          }
        });
      }
    } else {
      console.log("This browser does not support notifications.");
    }
  }, []);

  const [searchParams] = useSearchParams();
  const chatId = searchParams.get('chatId');
  const groupChatId = searchParams.get('groupChatId');

  const showNotification = message => {
    const isInTheChat =
        (location.pathname.includes('messages') || groupChatId || chatId) &&
        (message?.userGroupChat
            ? message.chatId === +chatId
            : message.groupChatId === +groupChatId);

    const isPageHidden = document.hidden;

    if ("Notification" in window) {
      if (Notification.permission === "granted" && (!isInTheChat || isPageHidden)) {
        const group = user?.groupChats?.find(
            group => group.id === message.groupChatId
        );

        const notificationFrom = !message?.userGroupChat
            ? group.name
            : `${message.User.firstName} ${message.User.lastName}`;

        const notification = new Notification(
            `New message from ${notificationFrom}`,
            {
              body: `${!message?.userGroupChat ? `${message.User.firstName}: ` : ''}${
                  message.text
              }`,
              icon: `https://platform.tesvan.com/server/${
                  message.groupChatId ? group.image : message.User.image
              }`,
            }
        );

        notification.onclick = function () {
          notification.close();
          window.open(
              `https://platform.tesvan.com/${user.role.toLowerCase()}/messages?groupChatId=${
                  message?.userGroupChat ? message?.userGroupChat : message.groupChatId
              }${message?.userGroupChat ? `&chatId=${message.chatId}` : ''}`,
              '_blank'
          );
        };
      } else {
        console.log("Notifications are denied.");
      }
    } else {
      console.log("This browser does not support notifications.");
    }
  };

  useEffect(() => {
    if (socket) {
      const handleMessage = (newMessage, type) => {
        if (newMessage.senderId !== user.id) {
          newMessageSoundRef.current.play().catch(error => console.log(error));
          showNotification(newMessage);
          console.log(newMessage)

          setNewMessages(prevMessages => {
            const key = type === 'chat' ? 'chatNotification' : 'groupChatNotification';
            const updatedNotifications = prevMessages[key]?.map(chat => {
              if (type === 'chat' ? (chat.chatId === newMessage.chatId) : (chat.id === newMessage.groupChatId)) {
                return {
                  ...chat,
                  notification: chat.notification + 1,
                  lastMessage: { id: newMessage.id, text: newMessage.text, createdAt: newMessage.createdAt }
                };
              }
              return chat;
            }) || [];

            const isChatExisting = updatedNotifications.some(chat => chat.chatId === newMessage.chatId);
            if (!isChatExisting) {
              updatedNotifications.push({
                chatId: newMessage.chatId,
                notification: 1,
                lastMessage: { id: newMessage.id, text: newMessage.text },
                ...(type === 'group' ? { groupName: newMessage.groupName } : {
                  firstId: newMessage.receiverId,
                  secondId: newMessage.senderId,
                  receiver: newMessage.User,
                  createdAt: newMessage.createdAt
                })
              });
            }

            return {
              ...prevMessages,
              [key]: updatedNotifications,
              [type === 'chat' ? 'groupChatNotification' : 'chatNotification']: [
                ...(prevMessages[type === 'chat' ? 'groupChatNotification' : 'chatNotification'] || []),
              ]
            };
          });
        }
      };

      const messageTypes = ['chat', 'group'];
      const sendTypes = ['create', 'reply'];

      messageTypes.forEach(type => {
        sendTypes.forEach(send => {
          const event = type === 'chat' ? `${send}ChatMessage` : `${send}GroupChatMessage`;
          socket?.on(event, (newMessage) => {
            handleMessage(newMessage, type)
            console.log(newMessage)
          });
        });
      });

      return () => {
        messageTypes.forEach(type => {
          sendTypes.forEach(send => {
            const event = type === 'chat' ? `${send}ChatMessage` : `${send}GroupChatMessage`;
            socket?.off(event);
          });
        });
      };
    }
  }, [socket, chatId]);

  useEffect(() => {
    if (!location.hash && (!location.search || location.search.includes('priceId') ) ) {
      scrollToTop();
    }
  }, [location]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const {i18n} = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'))
  }, [])

  useEffect(() => {
    const language = localStorage.getItem('language');
    if(language) {
      document.body.classList.add(language);
    } else {
      document.body.classList.add("am");
    }

    return () => {
      if(language) {
        document.body.classList.remove(language);
      } else {
        document.body.classList.remove("am");
      }
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const {setCourseStatus, setBoughtCourses} = useUserCourseContext()

  useEffect(() => {
    if (localStorage.getItem('authToken') || sessionStorage.getItem('authToken')) {
      setStatus('loading');
      axios.get('/user/authMe').then(({ data }) => {
        setUser(data.User);
        setStatus('fulfilled');
      }).catch(() => {
        setStatus('error');
        localStorage.removeItem('authToken');
        sessionStorage.removeItem('authToken');
      });
    } else {
      setStatus('error');
    }
  }, [setStatus, setUser]);


  useEffect(() => {
    if (user && user.role === ROLES.STUDENT) {
      setCourseStatus('loading');
      axios.get('courses/getUserCourses').then(({ data }) => {
        setBoughtCourses(data.courses);
        setCourseStatus('fulfilled');
      }).catch(() => setCourseStatus('error'));
    }
  }, [user, setBoughtCourses, setCourseStatus])

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language])

  return (
      <Routes>
        <Route path="/" element={<Layout hasNewMessages={hasNewMessages} setHasNewMessages={setHasNewMessages} newMessages={newMessages} />}>
          <Route index element={<HomePage />} />
          <Route path="platform" element={<Platform />} />
          <Route path="registration" element={<Registration />} />
          <Route path="verification" element={<Verification />} />
          <Route path="verify" element={<Verify />} />
          <Route path="changeEmail" element={<ChangeEmail />} />
          <Route path="login" element={<Login />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route path="changePassword" element={<ChangePassword />} />
          <Route path="courses" element={<Courses />} />
          <Route path="courses/:id" element={<SingleCourse />} />
          <Route path="cookies" element={<Cookies />} />
          <Route path="termsOfUse" element={<TermsOfUse />} />
          <Route path="privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="company/FAQ" element={<FAQ />} />
          <Route path="company/contactUs" element={<ContactUs />} />
          <Route path="company/about" element={<About />} />
          <Route path="payment/:groupId" element={<Payment />} />
          <Route path={'paymentSuccess'} element={<PaymentSuccess />} />
          <Route path={'paymentFail'} element={<PaymentFail />} />
          <Route path={'settingsVerify'} element={<UserSettingsVerificate />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>

        <Route path="student" element={<BoughtCoursesLoader />}>
          <Route path='empty' element={<RolesLayout role={ROLES.STUDENT} children={<DashLayout hasNewMessages={hasNewMessages} setHasNewMessages={setHasNewMessages} newMessages={newMessages} />} />}>
            <Route index element={<NoCourseBought />} />
            <Route path="settings" element={<Navigate to="account" replace />} />
            <Route path="settings" element={<Settings />}>
              <Route path="account" element={<Account />} />
              <Route path="knowledge" element={<Knowledge />} />
              <Route path="password" element={<PasswordUpdate />} />
              <Route path="delete" element={<DeleteAccount />} />
            </Route>
            <Route path="test" element={<OverallProgressTests />} />
            <Route path="test/quiz" element={<StudentTest />} />
          </Route>
            <Route element={<RolesLayout role={ROLES.STUDENT} children={<DashLayout hasNewMessages={hasNewMessages} setHasNewMessages={setHasNewMessages} newMessages={newMessages} />} />} >
              <Route index element={<Home />} />
              <Route path="courses" element={<CourseLayout children={<HomeCourses />} />} />
              <Route path="courses/:idcourses" element={<CourseLayout children={<CoursLessons />} />} />
              <Route path="courses/:idcourses/:lessonId" element={<CourseLayout children={<LessonsProgress />} />} />
              <Route path="courses/:idcourses/:lessonId/quiz/:quizIdStudnets" element={<CourseLayout children={<QuizSections />} />} />
              <Route path="courses/:idcourses/final-quiz/:quizIdStudnets" element={<CourseLayout children={<QuizSections />} />} />
              <Route path="courses/:idcourses/:lessonId/homework/:homeworkStudents" element={<CourseLayout children={<SingleHomework />} />} />
              <Route path="payment" element={<PaymentTable />} />
              {/*<Route path="courses/:idcourses/:lessonId/video" element={<CourseLayout children={<VideoCourse />} />} />*/}
              <Route path="messages" element={<CourseLayout children={<Chat socket={socket} connectedUser={connectedUser} newMessages={newMessages} setNewMessages={setNewMessages} />} />}></Route>
              <Route path="certification" element={<CourseLayout children={<Certification />} />} />
              <Route path="calendar/:view/:date" element={<CourseLayout children={<BigCalendar />} />} />
              <Route path="calendar" element={<Navigate to={`month/${moment(new Date()).format('YYYY-MM-DD')}`} replace />} />
              <Route path="settings" element={<Navigate to="account" replace />} />
              <Route path="settings" element={<Settings />}>
                <Route path="account" element={<Account />} />
                <Route path="knowledge" element={<Knowledge />} />
                <Route path="password" element={<PasswordUpdate />} />
                <Route path="delete" element={<DeleteAccount />} />
              </Route>
              <Route path="test" element={<OverallProgressTests />} />
              <Route path="test/quiz" element={<StudentTest />} />
            </Route>
        </Route>

        <Route path="teacher" element={<RolesLayout role={ROLES.TEACHER} children={<DashLayout hasNewMessages={hasNewMessages} setHasNewMessages={setHasNewMessages} newMessages={newMessages} />} />}>
          <Route index element={<Teacher />} />
          <Route path="settings" element={<Navigate to="account" replace />} />
          <Route path="settings" element={<Settings />}>
            <Route path="account" element={<Account />} />
            {/*<Route path="permissions" element={<Permissions />} />*/}
            <Route path="password" element={<PasswordUpdate />} />
            <Route path="delete" element={<DeleteAccount />} />
          </Route>
          <Route path="studentsTable" element={<StudentsTable />} />
          <Route path="users/student/:userInfoId" element={<StudentAdminInfo />} />
          <Route path="mygroup/:mygroupid/students/info/:infoId" element={<StudentInfo />} />
          <Route path="mygroup" element={<AdminMyGroup />} />
          <Route path="mygroup/:memberId" element={<GroupID />} >
            <Route path="members" element={<AdminGroupMembers />} />
            <Route path="homeworks/:courseId" element={<HomeworkPerGroup />} />
            <Route path="studentsProgress" element={<StudentsTable />} />
            <Route path="homeworks/:courseId/:homeworkId" element={<TeacherSingleHomework />} />
            <Route path="homeworks/:courseId/:homeworkId/:userId" element={<UserHomework />} />
          </Route>
          <Route path="homework/:homeworkId" element={<TeacherSingleHomework />} />
          <Route path="calendar/:view/:date" element={<BigCalendar />} />
          <Route path="calendar" element={<Navigate to={`month/${moment(new Date()).format('YYYY-MM-DD')}`} replace />} />
          <Route path="myCourses" element={<HomeCourses />} />
          <Route path="myCourses/:idcourses" element={<CoursLessons />} />
          <Route path="myCourses/:idcourses/:lessonId" element={<LessonsProgress />} />
          <Route path="myCourses/:idcourses/:lessonId/video" element={<VideoCourse />} />
          <Route path="myCourses/:idcourses/:lessonId/:answerQuiz/result" element={<StudentQuizResults />} />
          <Route path="myCourses/:idcourses/:lessonId/answerQuiz" element={<QuizAnswers />} />
          <Route path="courses" element={<AdminCourses />} />
          <Route path="courses/newcourse" element={<CreateNewCourseAdmin />} />
          <Route path="courses/:courseIdEdit" element={<CourseID />} >
            <Route path="lessons" element={<AdminCourseLessons />} />
            <Route path="settings" element={<AdminCourseSettings />} />
          </Route>
          <Route path="messages" element={<Chat socket={socket} connectedUser={connectedUser} newMessages={newMessages} setNewMessages={setNewMessages} />} />
          <Route path="homework" element={<Homework />} />
          <Route path="homework/create" element={<AdminCreateHomework />} />
          <Route path="homework/edit/:homeworkId" element={<HomeworkEdit />} />
          {/*<Route path="homework/group/:courseId" element={<HomeworkPerGroup />} />*/}
          <Route path="homework/group/:courseId/:homeworkId" element={<TeacherSingleHomework />} />
          <Route path="homework/group/:courseId/:homeworkId/:userId" element={<UserHomework />} />
          <Route path="homework/:homeworkId" element={<TeacherSingleHomework />} />
          <Route path="quizzes" element={<AdminCreateNewQuiz />} />
          <Route path="quizzes/addquiz" element={<AdminAddQuiz />} />
          <Route path="quizzes/editquizz/:quizEditId" element={<EditQuizz />} />
          <Route path="lessons" element={<AdminCreateLessons />} />
          <Route path="lessons/newlessons" element={<AdminCreateNewLessons />} />
          <Route path="lessons/editlesson/:editId" element={<EditLessons />} />
          <Route path="certification" element={<CertificationTeacher />} />
        </Route>

        <Route path="admin" element={<RolesLayout role={ROLES.ADMIN} children={<DashLayout hasNewMessages={hasNewMessages} setHasNewMessages={setHasNewMessages} newMessages={newMessages} />} />}>
          <Route index element={<AdminDashboard />} />
          <Route path="messages" element={<Chat socket={socket} connectedUser={connectedUser} newMessages={newMessages} setNewMessages={setNewMessages} />} />
          <Route path="courses" element={<AdminCourses />} />
          <Route path="courses/newcourse" element={<CreateNewCourseAdmin />} />
          {/*<Route path="courses/:courseIdEdit" element={<CourseID />} >*/}
          {/*  <Route path="lessons" element={<AdminCourseLessons />} />*/}
          {/*  /!*<Route path="participant" element={<AdminCourseParticipant />} />*!/*/}
          {/*  <Route path="settings" element={<AdminCourseSettings />} />*/}
          {/*</Route>*/}
          <Route path="payment" element={<PaymentGroups />} />
          <Route path="payment/table/:groupId" element={<AdminPayment />} />
          <Route path="mygroup" element={<AdminMyGroup />} />
          <Route path="mygroup/createGroup" element={<CreateGroup />} />
          <Route path="mygroup/:editGroup/edit" element={<EditeGroupNew />} />
          <Route path="mygroup/:memberId" element={<GroupID />} >
            <Route path="members" element={<AdminGroupMembers />} />
            {/*<Route path="assessment" element={<AdminGroupAssessment />} />*/}
          </Route>
          <Route path="users" element={<AdminUsers />} />
          <Route path="subscriptions" element={<Subscrictions />} />
          <Route path="website" element={<Navigate to={'testimonials'} replace />} />
          <Route path="website" element={<AdminWebsite />} >
            <Route path='testimonials' element={<Testimonials />}/>
            <Route path='faqs' element={<FAQs />}/>
            <Route path='blog' element={<BlogAdmin />}/>
            <Route path='careers' element={<Careers />}/>
            <Route path='contacts' element={<Contacts />}/>
          </Route>
          <Route path="users/student/:userInfoId" element={<StudentAdminInfo />} />
          <Route path="users/teacher/:teacherInfoId" element={<TeacherAdminInfo />} />
          <Route path="homework" element={<Homework />} />
          <Route path="homework/create" element={<AdminCreateHomework />} />
          <Route path="homework/edit:homeworkId" element={<HomeworkEdit />} />
          <Route path="homework/group/:courseId" element={<HomeworkPerGroup />} />
          <Route path="homework/group/:courseId/:homeworkId" element={<TeacherSingleHomework />} />
          <Route path="homework/group/:courseId/:homeworkId/:userId" element={<UserHomework />} />
          <Route path="homework/:homeworkId" element={<TeacherSingleHomework />} />
          <Route path="settings" element={<Navigate to="account" replace />} />
          <Route path="settings" element={<Settings />}>
            <Route path="account" element={<Account />} />
            {/*<Route path="permissions" element={<Permissions />} />*/}
            <Route path="password" element={<PasswordUpdate />} />
            <Route path="delete" element={<DeleteAccount />} />
          </Route>
          <Route path="calendar" element={<Navigate to={`month/${moment(new Date()).format('YYYY-MM-DD')}`} replace />} />
          <Route path="calendar/:view/:date" element={<BigCalendar />} />
          <Route path="certification" element={<CertificationTeacher />} />
          <Route path="tests" element={<AdminTest />} />
          <Route path="tests/createTest" element={<AdminCreateATest />} />
          <Route path="tests/createTest/:testEditId" element={<EditTest />} />
          <Route path="quizzes" element={<AdminCreateNewQuiz />} />
          <Route path="quizzes/addquiz" element={<AdminAddQuiz />} />
          {/*<Route path="quizzes/editquizz/:quizEditId" element={<EditQuizz />} />*/}
          <Route path="lessons" element={<AdminCreateLessons />} />
          <Route path="lessons/newlessons" element={<AdminCreateNewLessons />} />
          {/*<Route path="lessons/editlesson/:editId" element={<EditLessons />} />*/}
        </Route>
      </Routes>
  );
}

export default App;
