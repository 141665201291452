import React, { useEffect, useState } from "react";
import styles from "./studentstable.module.css";
import {Link, useParams} from "react-router-dom";
import axios from "../../axios/axios";
import Button from "../../Components/Button/button";
import {useUserContext, ROLES} from "../../Components/UserContext/UserContext";


const tHeaders = [
    { name: "Name Surname", field: "name" },
    { name: "Quiz Points", field: "quizz" },
    { name: "Homework Points", field: "homework" },
    { name: "Int. Points", field: "interview" },
    { name: "Total Points", field: "total" },
    { name: "Time", field: "time" }
];

function StudentsTable() {
    const { memberId } = useParams();
    const [users, setUsers] = useState();
    const [group, setGroup] = useState(null)
    const [isCourseFinished, setIsCourseFinished] = useState(false)
    const [order, setOrder] = useState("ASC");
    const [orderName, setOrderName] = useState("name");
    const { user } = useUserContext();

    useEffect(() => {
        axios
            .get(`/group/findOneTeacher/${memberId}`, { params: { order, orderName } })
            .then(({ data }) => {
                setUsers(data?.users)
                setGroup(data?.group)
            })
            .catch(error => console.log(error));
    }, [memberId, order, orderName]);

    const handleSort = (field) => {
        const isAsc = order === "ASC";
        setOrder(isAsc ? "DESC" : "ASC");
        setOrderName(field);
    };

    const handleFinishCourse = () => {
        axios.patch(`group/finishGroup/${memberId}`).then(() => setIsCourseFinished(true))
    }
    const handleFormatMinutes = time => {
            if (time === null || time === undefined || isNaN(time) || time < 0) {
                return;
            }

            let hours = Math.floor(time / 60);
            let minutes = time % 60;

            return (hours || minutes) ? `${hours}h ${minutes}m` : '0';
    };

    return (
        <>
            <div className={styles.titleContainer}>
                <p className={styles.title}>{group?.name}</p>
                {user.role === ROLES.ADMIN &&
                    <Button
                        text={'Finish Group'}
                        className={'bigPrimary'}
                        styles={{padding: '10px 25px', alignSelf: 'center'}}
                        handleButtonClick={handleFinishCourse}
                        disabled={isCourseFinished || group?.finished}
                    />
                }
            </div>
            <table className={styles.table}>
                <thead>
                <tr>
                    {tHeaders.map(({ name, field }, i) => (
                        <th key={i} onClick={() => handleSort(field)}>
                            <span>{name}</span>
                            {orderName === field ? (order === "ASC" ? " ▲" : " ▼") : null}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {users?.map(user => (
                    <tr key={user?.id}>
                        <td><Link to={`/teacher/users/student/${user.id}`}>{user?.firstName} {user?.lastName}</Link></td>
                        <td>{user?.quizPoint}/{group?.maxQuizzPoint}</td>
                        <td>{user?.homeworkPoint}/{group?.maxHomeworkPoint}</td>
                        <td>{user?.interviewPoint}/{group?.maxInterviewPoint}</td>
                        <td><span className={styles.totalPoints}>{user?.totalPoints}</span></td>
                        <td>{handleFormatMinutes(user?.totalTime)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );
}

export default StudentsTable;
