import React from "react";
import styles from './header.module.css'
import {useTranslation} from "react-i18next";

function Header({small, big, text, buttonCont, img, imgClass, short, children, letters}) {

    const {i18n} = useTranslation()

    return(
        <section className={styles.container}>
            <div className={styles.headcont} style={children ? {paddingTop: 160} : undefined}>
                {short ?
                    <p className={`${styles.big} ${styles[`big_${i18n.language}`]}`} style={{color: ''}}>
                        {big}
                    </p> :
                    <div className={styles.head}>
                        <p className={`${styles.small} ${styles[`small_${i18n.language}`]}`}>{small}</p>
                        <p className={`${styles.big} ${styles[`big_${i18n.language}`]}`}>{big}</p>
                    </div>}
                    <p className={styles.text}>{text}</p>
                    <div className={styles.button}>
                        {buttonCont}
                    </div>
                {children}
                </div>
            <div className={styles.imgcont}>
                <img src={img} className={imgClass} alt={'image'}/>
                <img src={'/images/frame.webp'} className={styles.img} alt={'frame'} />
            </div>
        </section>
    )
}

export default Header;