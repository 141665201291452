import React, { useEffect, useState } from 'react';
import styles from './AdminAddQuiz.module.css';
import Button from '../../../../../../../Components/Button/button';
import AdminModal from '../../../../CreateNewCourseAdmin/AdminModal/AdminModal';
import axios from '../../../../../../../axios/axios';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../../../../../../Components/UserContext/UserContext';

function EditQuiz() {
    const { quizEditId } = useParams();
    const [questions, setQuestions] = useState([]);
    const [lessonTitles, setLessonTitles] = useState({ en: '', ru: '', am: '' });
    const [motivations, setMotivations] = useState({ en: '', ru: '', am: '' });
    const [price, setPrice] = useState('');
    const [modOpen, setModOpen] = useState(false);
    const [selectedButton, setSelectedButton] = useState('EN');
    const [point, setPoint] = useState('')
    const {user} = useUserContext() 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [quizResponse] = await Promise.all([
                    axios.get(`/quizz/getQuizzesAdmin/${quizEditId}`),
                ]);
                const quizData = quizResponse.data.quizz;
                setQuestions(quizData.Questions.map(question => ({
                    id: question.id,
                    title_en: question.title_en,
                    title_ru: question.title_ru,
                    title_am: question.title_am,
                    options: question.Options.map(option => ({
                        id: option.id,
                        title_en: option.title_en,
                        title_ru: option.title_ru,
                        title_am: option.title_am,
                        isCorrect: option.isCorrect,
                    }))
                })));
                setLessonTitles({
                    en: quizData.title_en,
                    ru: quizData.title_ru,
                    am: quizData.title_am
                });
                setMotivations({
                    en: quizData.description_en,
                    ru: quizData.description_ru,
                    am: quizData.description_am
                });
                setPrice(quizData.time);
                setPoint(quizData?.point)
            } catch (error) {
                console.error('Error fetching quiz details:', error);
            }
        };

        fetchData();

    }, [quizEditId]);

    const onChangeTitle = (index, value, language) => {
        setQuestions(prevQuestions =>
            prevQuestions.map((q, ind) => (ind === index ? { ...q, [`title_${language}`]: value } : q))
        );
    };

    const onChangeAnswer = (questionIndex, answerIndex, value, language) => {
        setQuestions(prevQuestions =>
            prevQuestions.map((q, ind) =>
                ind === questionIndex
                    ? {
                        ...q,
                        options: q.options.map((a, aInd) =>
                            aInd === answerIndex ? { ...a, [`title_${language}`]: value } : a
                        ),
                    }
                    : q
            )
        );
    };

    const onSelectTrueAnswer = (questionIndex, answerIndex) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q, ind) =>
                ind === questionIndex
                    ? {
                        ...q,
                        options: q.options.map((a, aInd) =>
                            aInd === answerIndex ? { ...a, isCorrect: !a.isCorrect } : { ...a, isCorrect: false }
                        ),
                    }
                    : q
            )
        );
    };

    const deleteQuestion = (index) => {
        setQuestions(prevQuestions => prevQuestions.filter((_, i) => i !== index));
    };

    const addNewQuestion = () => {
        const newQuestion = {
            id: null,
            title_en: '',
            title_ru: '',
            title_am: '',
            options: [
                { id: null, title_en: '', title_ru: '', title_am: '', isCorrect: false },
                { id: null, title_en: '', title_ru: '', title_am: '', isCorrect: false },
                { id: null, title_en: '', title_ru: '', title_am: '', isCorrect: false },
                { id: null, title_en: '', title_ru: '', title_am: '', isCorrect: false },
            ],
        };

        setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
    };

    const updateQuiz = async () => {
        try {
            const quizData = {
                time: +price,
                point: +point,
                title_en: lessonTitles.en,
                title_ru: lessonTitles.ru,
                title_am: lessonTitles.am,
                description_en: motivations.en,
                description_ru: motivations.ru,
                description_am: motivations.am,
                questions: questions.map(question => ({
                    id: question.id,
                    title_en: question.title_en,
                    title_ru: question.title_ru,
                    title_am: question.title_am,
                    options: question.options.map(option => ({
                        id: option.id,
                        title_en: option.title_en,
                        title_ru: option.title_ru,
                        title_am: option.title_am,
                        isCorrect: option.isCorrect || false,
                    })),
                })),
            };

            const response = await axios.put(`/quizz/update/${quizEditId}`, quizData);
            console.log('Quiz updated successfully:', response.data);
            setModOpen(true);
        } catch (error) {
            console.error('Error updating quiz:', error);
        }
    };

    const handlePriceChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setPrice(inputValue);
        }
    };

    const handleClick = (button) => {
        setSelectedButton(button);
    };

    const handlePointsChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue === '' || /^\d*\.?\d*$/.test(inputValue)) {
            setPoint(inputValue);
        }
    };

    return (
        <div>
            <p className={styles.tit}>Edit Quiz</p>

            <div className={styles.cont}>
                <div className={styles.top}>
                    <p>Edit Quiz</p>
                    <p>{questions.length} questions</p>
                </div>
                <div className={styles.btndiv}>
                    <button
                        className={`${styles.tagOption} ${selectedButton === 'EN' ? styles.selected : ''}`}
                        onClick={() => handleClick('EN')}
                    >
                        ENG
                    </button>
                    <button
                        className={`${styles.tagOption} ${selectedButton === 'AM' ? styles.selected : ''}`}
                        onClick={() => handleClick('AM')}
                    >
                        ARM
                    </button>
                    <button
                        className={`${styles.tagOption} ${selectedButton === 'RU' ? styles.selected : ''}`}
                        onClick={() => handleClick('RU')}
                    >
                        RUS
                    </button>
                </div>
                <div className={styles.inpDiv}>
                    <div className={styles.controler}>
                        <img className={styles.imgclosk} src={'/images/clockquiz.png'} alt={'cube'} />
                        <input className={styles.input} value={price} onChange={handlePriceChange} type={'text'}
                               placeholder={'Add timer'} />
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <div className={styles.controler2}>
                        <img className={styles.img} src={'/images/cubeyellow.png'} alt={'cube'} />
                        <input
                            type={'text'}
                            className={styles.input}
                            value={lessonTitles[selectedButton.toLowerCase()]}
                            placeholder={'Write the title of the quiz'}
                            onChange={(e) => setLessonTitles(prevState => ({
                                ...prevState,
                                [selectedButton.toLowerCase()]: e.target.value
                            }))}
                        />
                    </div>
                    <div className={styles.controler1}>
                        <img className={styles.img} src={'/images/cubeyellow.png'} alt={'cube'} />
                        <input
                            type={'text'}
                            className={styles.input}
                            value={motivations[selectedButton.toLowerCase()]}
                            placeholder={'Write the motivation quotes for students.'}
                            onChange={(e) => setMotivations(prevState => ({
                                ...prevState,
                                [selectedButton.toLowerCase()]: e.target.value
                            }))}
                        />
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <div className={styles.controler}>
                        <input className={styles.input} value={point} onChange={handlePointsChange} type={'text'} placeholder={'Points'}/>
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    {questions.map((question, qIndex) => (
                        <div key={qIndex} className={styles.only}>
                            <p className={styles.que}>Question {qIndex + 1}</p>
                            <div className={styles.controler}>
                                <img className={styles.img} src={'/images/cube.png'} alt={'cube'} />
                                <textarea
                                    className={styles.textarea}
                                    placeholder={'Write the title of the quiz'}
                                    value={question[`title_${selectedButton.toLowerCase()}`]}
                                    onChange={(e) => onChangeTitle(qIndex, e.target.value, selectedButton.toLowerCase())}
                                />
                            </div>
                            {question.options.map((answer, aIndex) => (
                                <div key={aIndex} className={styles.controler}>
                                    <img
                                        className={styles.img}
                                        src={'/images/cube.png'}
                                        alt={'cube'}
                                    />
                                    <textarea
                                        className={styles.textarea}
                                        placeholder={`Answer ${aIndex + 1}`}
                                        value={answer[`title_${selectedButton.toLowerCase()}`]}
                                        onChange={(e) => onChangeAnswer(qIndex, aIndex, e.target.value, selectedButton.toLowerCase())}
                                    />
                                    <img
                                        src={'/images/trueacces.png'}
                                        className={answer.isCorrect ? styles.correct : styles.incorrect}
                                        alt={answer.isCorrect ? 'correct' : 'incorrect'}
                                        onClick={() => onSelectTrueAnswer(qIndex, aIndex)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    {/* Hidden checkbox */}
                                    <input
                                        type="checkbox"
                                        checked={answer.isCorrect}
                                        style={{ display: 'none' }}
                                        onChange={() => onSelectTrueAnswer(qIndex, aIndex)}
                                    />
                                </div>
                            ))}
                            {questions.length > 1 && <div className={styles.delete}>
                                <button className={styles.deleteButton}
                                        onClick={() => deleteQuestion(qIndex)}>Delete this one
                                </button>
                            </div>}
                        </div>
                    ))}
                </div>
                <div className={styles.questdiv}>
                    <button onClick={addNewQuestion} className={styles.quest}> Add new question</button>
                </div>
                {questions.length > 1 && (
                    <div className={styles.bot}>
                        <div className={styles.bts}>
                            <Button handleButtonClick={updateQuiz} text={'Edit Quiz'} className={'bigPrimary'} />
                        </div>
                    </div>
                )}
            </div>
            {modOpen && <AdminModal link={`/${user?.role.toLowerCase()}/quizzes`} setOpen={setModOpen}
                                    question={'The quiz has been successfully updated.'} />}
        </div>
    );
}

export default EditQuiz;
