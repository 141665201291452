import React, {useEffect, useState} from "react";
import styles from'../../Registration/Verification/ChangeEmail/chnageemail.module.css'
import RegPage  from "../../Registration/RegPage/RegPage";
import Button from "../../../Components/./Button/button";
import axios from "../../../axios/axios";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function ForgotPassword() {

    const [email, setEmail] = useState('')
    const [emailErr, setEmailErr] = useState(null)
    const [error, setError] = useState(null)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [disabled, setDisabled] = useState(true);
    const [time, setTime] = useState(30);
    const [run, setRun] = useState(true);
    const [start, setStart] = useState(false);
    const {t} = useTranslation();
    const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    useEffect(() => {
        if (run && time > 0) {
            const intervalId = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
        if (time ===  0) {
            setRun(false);
            setDisabled(true);
        }
        setTimeout(() => {
            setDisabled(true);
        }, 30000);
    }, [time, run]);


    const sendEmail = () => {
        axios.get('/user/ForgotPassword', {
            params: {
                email
            }
        }).catch(error => {
            console.log(error)
        })

        setDisabled(false);
        setTime(30);
        setRun(true);
        setStart(true);
    }

    const submitHandle = () => {
        if(email) {
            axios.get('/user/ForgotPassword', {
                params: {
                    email
                }
            })
                .then(response => {
                    setIsSubmitted(true);
                })
                .catch(error => {
                    setError(error.response.data.message)
                });
        } else {
            setEmailErr(true)
        }
    }

    return(
        <>
            <Helmet>
                <title>Forgot Password - Reset Your Access</title>
                <meta name="description" content="Reset your password if you've forgotten it. Follow simple steps to regain access to your account." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <RegPage
                content={
                    <div className={styles.container}>
                            <img src='/images/forgotPass.png' className={styles.image} alt={'forgotPassword'}/>
                            <p className={styles.heading}>
                                {t('resetPassword.forgotPassword.forgotPassword')}
                            </p>
                        <p className={styles.text}>
                            { !isSubmitted
                                ? t('resetPassword.forgotPassword.text')
                                : `${t('resetPassword.forgotPassword.emailIsSentTextFirstPart')} ${email} ${t('resetPassword.forgotPassword.emailIsSentTextSecondPart')}`
                            }
                        </p>
                        { !isSubmitted ?
                            <>
                            <div className={styles.inputcont} style={{width: '48%'}}>
                                <label className={styles.label}>
                                    <span className={styles.caption}> {t('registration.firstStep.email')} </span>
                                    <input
                                        type="text"
                                        className={`${styles.input} ${emailErr === null ?  "" : emailErr || error  ? styles.errorinp : styles.noterrinp}`}
                                        value={email}
                                        placeholder="johnsmith@example.com"
                                        onChange={(e) => setEmail(e.target.value)}
                                        onBlur={() => {
                                            setEmailErr(!emailRegex.test(email));
                                        }}
                                    />
                                </label>
                                {emailErr && !error && <p className={styles.error}>{t('registration.firstStep.emailError')}</p>}
                                {error && <p className={styles.error}>{t('resetPassword.forgotPassword.notVerifiedUserError')}</p>}
                            </div>
                        <div className={styles.cont}>
                            <Button
                                text={t('resetPassword.forgotPassword.send')}
                                className={'primary'}
                                handleButtonClick={submitHandle}
                            />
                        </div>
                        </>
                            :
                            <>
                            <p className={styles.spam}>{t('registration.verification.spamFolder')}</p>
                                <div className={styles.con}>
                                    <Button
                                        className="primary"
                                        text={t('registration.verification.resend')}
                                        disabled={!disabled}
                                        handleButtonClick={sendEmail}
                                    />
                                    {start &&
                                        <p className={styles.timer}>
                                            {time.toString().length === 1 ? `00:0${time}` : `00:${time}`}
                                        </p>
                                    }
                                </div>
                            </>
                        }
                        <span className={styles.back}>{t('resetPassword.forgotPassword.back')} <Link to='/login' className={styles.link}>{t('logIn.logIn')}</Link> </span>
                    </div>
                }
                title={t('resetPassword.forgotPassword.title')}
                img={'/images/loginGirl.png'}
                className={styles.imgg}
            />
        </>
    )
}

export default ForgotPassword;



