import React, { useRef, useState } from "react";
import styles from './ourstory.module.css';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

function OurStory() {
    const { t, i18n } = useTranslation();

    const info = [
        {
            text: t('aboutUs.ourStory.year19'),
            image: '/images/ourStory.png',
            year: 2019
        },
        {
            text: t('aboutUs.ourStory.year20'),
            image: '/images/ourStory2020.jpg',
            year: 2020
        },
        {
            text: t('aboutUs.ourStory.year21'),
            image: '/images/ourStory2021.jpg',
            year: 2021
        },
        {
            text: t('aboutUs.ourStory.year22'),
            image: '/images/ourStory2022.jpg',
            year: 2022
        },
        {
            text: t('aboutUs.ourStory.year23'),
            image: '/images/ourStory2023.jpg',
            year: 2023
        },
        {
            text: t('aboutUs.ourStory.year24'),
            image: '/images/ourStory2024.jpg',
            year: 2024
        }
    ];

    const sliderRef = useRef(null);
    const [selectedYear, setSelectedYear] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: false,
        autoplay: true,
        autoplaySpeed: 5000,
        draggable: false,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (currentSlide, nextSlide) => {
            setSelectedYear(nextSlide);
        },
    };

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const goToSlide = (index) => {
        sliderRef.current.slickGoTo(index);
        setSelectedYear(index);
    };

    return (
        <section>
            <p className={`${styles.head} ${styles[`head_${i18n.language}`]}`}>
                {t('aboutUs.ourStory.title')}
            </p>
            <div className={styles.sliderBlock} id={'ourStory'}>
                <div className={styles.yearContainer}>
                    {info.map((item, index) => (
                        <div
                            key={index}
                            className={`${styles.year} ${selectedYear === index ? styles.selectedYear : ''}`}
                            onClick={() => goToSlide(index)}
                        >
                            {item.year}
                            {selectedYear === index && (
                                <img src={'/images/yellowTop.png'} className={styles.yellow} alt={'arrow'} />
                            )}
                        </div>
                    ))}
                </div>
                <Slider {...settings} ref={sliderRef}>
                    {info.map((i, index) => (
                        <div className={styles.container} key={index}>
                            <img src={i.image} className={styles.image} alt={'about'} />
                            <div className={styles.cont}>
                                <p className={styles.text}>
                                    {i.text}
                                </p>
                            </div>
                            <div className={styles.arrowcont}>
                                <img src={'/images/leftArrow.png'} className={styles.arrow} alt={'left'} onClick={prevSlide} />
                                <img src={'/images/rightArrow.png'} className={styles.arrow} alt={'right'} onClick={nextSlide} />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
}

export default OurStory;
