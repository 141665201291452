import React from "react";
import styles from "./error.module.css"
import {useNavigate} from "react-router-dom";
import Button from '../../Components/./Button/button'
import {Helmet} from "react-helmet-async";


function ErrorPage() {

    const navigate = useNavigate()

    return(
        <>
            <Helmet>
                <title>Page Not Found - Error 404</title>
                <meta name="description" content="Oops! The page you are looking for cannot be found. Return to the home page or explore our platform to find what you need." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <div className={styles.container}>
                <img src={"/images/errorFrame.png"} alt={"Frame"} className={styles.frame}/>
                <img  src={"/images/errorGirl.png"} alt={"Error"} className={styles.image} />
                <p className={styles.head}>
                    OOPS! PAGE NOT FOUND
                </p>
                <Button
                    styles={
                        {
                            position: 'absolute',
                            transform: 'translateX(-50%)',
                            left: '50%',
                            padding: '12px 40px'
                        }
                    }
                    className={"bigPrimary"}
                    text={"Go home"}
                    handleButtonClick={() => navigate("/")}
                />
            </div>
        </>
    )
}

export default ErrorPage;