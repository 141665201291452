import React, {useState} from "react";
import styles from './payingmodal.module.css'
import Checkbox from "../../../Components/Checkbox/Checkbox";
import Button from "../../../Components/./Button/button";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {IoClose} from "react-icons/io5";
import {useTranslation} from "react-i18next";

function PayingModal({setOpen, paymentData, payment}) {

    const [checked, setChecked] = useState(false)
    const navigate = useNavigate()
    const {id} = useParams()
    const [searchParams] = useSearchParams();
    const groupId = searchParams.get("groupId");
    const paymentMethod = payment.find(method => method.id === paymentData.paymentId)
    const {t, i18n} = useTranslation()

    const handleNavigate = () => {
        navigate(`/payment/${id ? id : groupId}?priceId=${paymentData.paymentId}&courseType=${paymentData.courseType}&method=${paymentMethod?.title.toLowerCase()}`)
    }

    return(
        <div className={styles.container} onClick={() => setOpen(false)}>
            <div className={styles.modal} onClick={e => e.stopPropagation()}>
                <IoClose className={styles.close} onClick={() => setOpen(false)}/>
                <p className={styles.title}>
                    {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.title')}
                </p>
                <div className={styles.contentCont}>
                    {paymentMethod?.title === 'Full' ?
                        <>
                            <p className={styles.text}>
                                {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.dearStudent')}
                            </p>
                            <p className={styles.text}>
                                <span className={styles.yellow}>{t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.refundPolicy')}:{' '}</span>
                                {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.refundPolicyDescription')}
                            </p>
                            <p className={styles.text}>
                                <span className={styles.yellow}>{t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.price')}:{' '}</span>
                                {paymentMethod.discount === 0 ? paymentMethod.price : (Math.round(paymentMethod.price - (paymentMethod.price * paymentMethod.discount)/100))} ֏
                            </p>
                            <p className={styles.text}>
                                <span className={styles.yellow}>{t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.schedule')}:{' '}</span>
                                {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.scheduleDescription')}
                            </p>
                            <p className={styles.text}>
                                {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.notation')}
                            </p>
                            <p className={styles.text}>
                                {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.termsOfUseFirstPart')}{' '}
                                <Link className={styles.link} to={'/termsOfUse'}>{t('footer.termsOfUse')}{i18n.language === 'am' ? 'ն' : ''}</Link>{' '}
                                {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.termsOfUseSecondPart')}
                            </p>
                        </> : paymentMethod?.title === 'Monthly' ?
                            <>
                                <p className={styles.text}>
                                    {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.dearStudent')}
                                </p>
                                <p className={styles.text}>
                                    <span className={styles.yellow}>{t('courseSinglePage.paymentMethods.paymentWays.monthlyPaymentTerms.monthlyFee')}:{' '}</span>
                                    {paymentMethod.discount === 0 ? paymentMethod.price : (Math.round(paymentMethod.price - (paymentMethod.price * paymentMethod.discount)/100))} ֏
                                </p>
                                <p className={styles.text}>
                                    <span className={styles.yellow}>{t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.refundPolicy')}:{' '}</span>
                                    {t('courseSinglePage.paymentMethods.paymentWays.monthlyPaymentTerms.refundPolicyDescription')}
                                </p>
                                <p className={styles.text}>
                                <span className={styles.yellow}>{t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.schedule')}:{' '}</span>
                                    {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.scheduleDescription')}
                                </p>
                                <p className={styles.text}>
                                    {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.notation')}
                                </p>
                                <p className={styles.text}>
                                    {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.termsOfUseFirstPart')}{' '}
                                    <Link className={styles.link} to={'/termsOfUse'}>{t('footer.termsOfUse')}{i18n.language === 'am' ? 'ն' : ''}</Link>{' '}
                                    {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.termsOfUseSecondPart')}
                                </p>
                                </> :
                            <>
                                <p className={styles.text}>
                                    Dear Valued Student, thank you for considering our course!
                                    We are excited to offer you our BootCamp program as an opportunity to embark on this transformative journey with us.
                                </p>
                                <p className={styles.text}><span className={styles.yellow}>Payment Options:</span></p>
                                <ul>
                                    <li>
                                        <span className={styles.yellow}>1.</span>{' '}
                                        Employment with Tesvan Company: After completing the BootCamp program, joining Tesvan Company exempts you from course fees.                                    </li>
                                    <li>
                                        <span className={styles.yellow}>2.</span>{' '}
                                        Early Withdrawal: If you decide to withdraw within the first month, no payment is required.
                                    </li>
                                    <li>
                                        <span className={styles.yellow}>3.</span>{' '}
                                        Job Placement: Upon completing the course, if you find employment within a year, monthly payments are required.
                                    </li>
                                    <li>
                                        <span className={styles.yellow}>4.</span>{' '}
                                        Unsuccessful Employment: If you don't find employment within a year, no payment is required.
                                    </li>
                                </ul>
                                <p className={styles.text}>
                                    Please note that this purchase provides access exclusively to the chosen course, but you are encouraged to explore and enroll in additional courses as desired.
                                </p>
                                <p className={styles.text}>
                                    Please take a moment to review the complete{' '}
                                    <Link className={styles.link} to={'/termsOfUse'}>Terms of use</Link>{' '}
                                    of the Tesvan Platform for additional information. By proceeding with the purchase of this course, you acknowledge and agree to adhere to these terms and conditions.
                                </p>
                            </>
                    }
                    <div className={styles.agreeCont}>
                        <Checkbox checkValue={checked} handleCheckboxChange={() => setChecked(!checked)} />
                        <p className={styles.agree}>
                            <span className={styles.yellow}>{t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.haveRead')}</span> {t('frequentlyUsedExpressions.and')} <span className={styles.yellow}>{t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.agree')}</span> {t('courseSinglePage.paymentMethods.paymentWays.fullPaymentTerms.agreeSequence')}
                        </p>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button text={t('frequentlyUsedExpressions.exit')} handleButtonClick={() => setOpen(false)} className={'secondary'} styles={{width: '150px'}} />
                    <Button text={t('frequentlyUsedExpressions.accept')} handleButtonClick={handleNavigate} className={'primary'} styles={{width: '150px'}} disabled={!checked} />
                </div>
            </div>
        </div>
    )
}

export default PayingModal