import React, {useEffect, useState} from "react";
import styles from './paymentmessage.module.css'
import RegPage from "../../Registration/RegPage/RegPage";
import Button from "../../../Components/Button/button";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "../../../axios/axios";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

function PaymentFail() {

    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const orderId = searchParams.get('orderId')
    const [groupId, setGroupId] = useState(null)
    const {t} = useTranslation()

    useEffect(() => {
        axios.post('/payment/buy', {
            orderKey: orderId
        }).then(({data}) => setGroupId(data?.groupId))
    }, [orderId])

    const handleDownloadInvoice = () => {
        axios.get(`/payment/downloadInvoice`, {
            params: { orderId },
            responseType: 'blob'
        }).then(({ data }) => {
            if (data.size === 0) {
                return;
            }
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            const filename = `Payment_Invoice.pdf`;

            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log("Error downloading invoice:", error);
        });
    };

    return (
        <>
            <Helmet>
                <title>Payment Failed - Please Try Again</title>
                <meta name="description" content="Your payment could not be processed. Please try again or contact support for assistance." />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            <RegPage
                title={t('payment.paymentStatus.title')}
                img={'/images/invalidGirl.png'}
                className={styles.image}
                content={
                    <div className={`${styles.container} ${styles.failGap}`}>
                        <img src={'/images/paymentFail.png'} className={styles.img} alt={'congratulation'}/>
                        <p className={styles.title}>
                            {t('payment.paymentStatus.fail.wentWrong')}
                        </p>
                        <p className={styles.text}>
                            {t('payment.paymentStatus.fail.text')}
                        </p>
                        <p className={styles.subText}>{t('payment.paymentStatus.fail.attemptAgain')}</p>
                        <div className={styles.invoiceCont}>
                            <Button
                                className={'bigPrimary'}
                                text={t('payment.paymentStatus.fail.tryAgain')}
                                handleButtonClick={() => navigate({pathname: `/courses/${groupId}`, hash: 'payment'})}
                            />
                            <Button
                                className={'bigPrimary'}
                                text={t('payment.paymentStatus.downloadInvoice')}
                                handleButtonClick={handleDownloadInvoice}
                            />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default PaymentFail