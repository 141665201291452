import styles from "../../../Dashboard/Homework/homework.module.css";
import Button from "../../../Components/Button/button";
import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "../../../axios/axios";
import moment from "moment/moment";
import {useUserContext} from "../../../Components/UserContext/UserContext";

function HomeworkPerGroup() {

    const { user } = useUserContext();
    const navigate = useNavigate();
    const [homeworks, setHomeworks] = useState([]);
    const [count, setCount] = useState(5);
    const [notHomework, setNotHomework] = useState(true);
    const {courseId} = useParams()

    useEffect(() => {
        axios
            .get('homework/getHomeworks/' + courseId)
            .then(({ data }) => {
                setHomeworks(data);
                setNotHomework(data.length === 0)
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleDelete = id => {
        axios.delete(`/homework/deleteHomework/${id}`)
            .then(() => {
                setHomeworks(homeworks.filter(homework => homework.id !== id));
            })
    };

    const renderHomeworkRow = homework => (
        <tr
            className={styles.selectable}
            key={homework.id}
            onClick={() => navigate(String(homework.id))}
        >
            <td>{homework.title}</td>
            <td>{moment(homework.dueDate).format("DD.MM.YYYY")}</td>
            {/*<td>{homework.maxPoints}</td>*/}
            <td>
                <Link to={`/${user.role.toLowerCase()}/homework/edit/${homework.id}`}>
                    <img onClick={e => e.stopPropagation()} className={styles.icons} src={'/images/paniconuser.png'} alt={'pan'} />
                </Link>
                <img onClick={e => {
                    handleDelete(homework.id)
                    e.stopPropagation()
                }} className={styles.icons} src={'/images/basketiconuser.png'} alt={'basket'}/>
            </td>
        </tr>
    );

    return(
        <div className={styles.container}>
            <table className={styles.table}>
                <tbody>
                {homeworks?.slice(0, count)?.map((homework) => renderHomeworkRow(homework))}
                </tbody>
            </table>
            {!notHomework && homeworks?.length > 5 && (
                <Button
                    className={"primary"}
                    text={count === homeworks?.length ? "See less" : "See more"}
                    handleButtonClick={() => {
                        setCount(count === 5 ? homeworks?.length : 5);
                    }}
                />
            )}
            {notHomework && <p className={styles.notHomework}>You don't have any homework yet.</p>}
        </div>
    )
}

export default HomeworkPerGroup


